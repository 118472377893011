import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { TripTrack } from './scenes/TripTrack'
import { LiveTV } from './scenes/LiveTV'
import { Kiosk } from './scenes/Kiosk'

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="/tv/:shortUrl" element={<LiveTV />} />
          <Route path="/kiosk/:shortUrl" element={<Kiosk />} />
          <Route path="/:shortUrl" element={<TripTrack />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
