import React from 'react'
import { ITripMarker } from '../TripMarker'
import { Polyline } from '../Polyline'

export const TripPreview: React.FC<ITripMarker> = ({ trip, token }) => {
  const points: { latitude: number; longitude: number }[] = []

  if (trip.vehicle && trip.vehicle.vehicleCoordinate) {
    points.push({
      latitude: trip.vehicle.vehicleCoordinate.closestRoadLatitude,
      longitude: trip.vehicle.vehicleCoordinate.closestRoadLongitude,
    })
  }
  for (const step of trip.steps) {
    if (step.place) {
      points.push({
        latitude: step.place.latitude,
        longitude: step.place.longitude,
      })
    }
  }

  return <Polyline points={points} token={token} authenticated="customer" />
}
