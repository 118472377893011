import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import io from 'socket.io-client'

import { DynamicMap } from '../../components/MapboxMaps/DynamicMap'
import { StoreMarker } from '../../components/MapboxMaps/StoreMarker'
import { VehicleMarker } from '../../components/MapboxMaps/VehicleMarker'

import './styles.css'
import { WaypointMarker } from '../../components/MapboxMaps/WaypointMarker'
import { Polyline } from '../../components/MapboxMaps/Polyline'

interface IStore {
  id: string
  name: string
  color: string
  iconUrl: string | null
  place: {
    id: string
    latitude: number
    longitude: number
    address: string
  }
  account: {
    id: string
  }
  liveTVDescription: string
  liveTVWaypoints: {
    id: string
    isVisible: boolean
    name: string
    content: string
    color: string
    position: number
    place: {
      id: string
      address: string
      latitude: number
      longitude: number
    }
  }[]
}

interface IVehicle {
  id: string
  driver: {
    id: string
    firstName: string
  } | null
  vehicleCoordinate: {
    id: string
    closestRoadLatitude: number
    closestRoadLongitude: number
    heading: number
  }
}

export const LiveTV: React.FC = () => {
  const { shortUrl } = useParams<{ shortUrl: string }>()

  const [token, setToken] = useState<string>('')
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })

  const [store, setStore] = useState<IStore | null>(null)
  const [vehicles, setVehicles] = useState<IVehicle[]>([])
  const [coordinates, setCoordinates] = useState<number[][]>([])
  const [polylinePoints, setPolylinePoints] = useState<{ latitude: number; longitude: number }[]>(
    [],
  )

  const vehiclesHash = JSON.stringify(vehicles)
  const storeHash = JSON.stringify(store)

  useEffect(() => {
    const authenticate = async () => {
      if (!shortUrl) return

      try {
        const response = await fetch(`${window.SC.apiUrl}/v2/auth/liveTV`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            liveTVShortUrl: shortUrl,
          }),
        })

        const json = await response.json()

        if (!json.error) {
          setToken(json.token)
        }
      } catch {}
    }

    authenticate()
  }, [shortUrl])

  useEffect(() => {
    let socket: SocketIOClient.Socket | null = null
    if (shortUrl && token) {
      fetch(`${window.SC.apiUrl}/v2/stores/liveTV/${shortUrl}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
          } else {
            setVehicles(json.vehicles.filter((vehicle: IVehicle) => vehicle.driver))

            setStore(json)

            setCenter({
              lat: json.place.latitude,
              lng: json.place.longitude,
            })

            socket = io(`${window.SC.apiUrl}/stores`, {
              path: '/ws',
              transports: ['websocket'],
            })

            socket.on('connect', () => {
              if (socket) {
                socket.emit('join', json.id)

                socket.on('/vehicles/coordinates', (message: string) => {
                  const vehicleMessage = JSON.parse(message)

                  setVehicles((prevState) => {
                    const filtered = prevState.filter((vehicle) => vehicle.id !== vehicleMessage.id)

                    return [...filtered, vehicleMessage]
                  })
                })
              }
            })
          }
        })
        .catch(() => {})
    }

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [shortUrl, token])

  const hasStoreImage: boolean = !!store?.iconUrl

  useEffect(() => {
    const storeParsed = JSON.parse(storeHash) as IStore | null
    const vehiclesParsed = JSON.parse(vehiclesHash) as IVehicle[]

    if (storeParsed) {
      const vehicleCoordinates = vehiclesParsed
        .filter((vehicle) => vehicle.vehicleCoordinate)
        .map((vehicle) => [
          vehicle.vehicleCoordinate.closestRoadLatitude,
          vehicle.vehicleCoordinate.closestRoadLongitude,
        ])
      const storeCoordinates = [storeParsed.place.latitude, storeParsed.place.longitude]
      const waypointCoordinates = storeParsed.liveTVWaypoints.map((w) => [
        w.place.latitude,
        w.place.longitude,
      ])

      setCoordinates([...vehicleCoordinates, ...waypointCoordinates, storeCoordinates])
      setPolylinePoints([
        { latitude: storeParsed.place.latitude, longitude: storeParsed.place.longitude },
        ...storeParsed.liveTVWaypoints.map((w) => ({
          latitude: w.place.latitude,
          longitude: w.place.longitude,
        })),
      ])
    }
  }, [vehiclesHash, storeHash])

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div className="livetv-header">
        {hasStoreImage && (
          <img
            src={`${window.SC.apiUrl}/v2/stores/${store?.id}/logo`}
            alt="logo"
            className="store-logo"
          />
        )}
        <div>
          <p
            className="text-dark mb-0 store-title"
            style={hasStoreImage ? { display: 'flex', alignItems: 'center' } : undefined}
          >
            {store?.name}
          </p>
          {store?.liveTVDescription && <p className="pt-1 mb-0">{store.liveTVDescription}</p>}
        </div>
      </div>
      <DynamicMap center={center} zoom={14} maxZoom={15} coordinates={coordinates}>
        {store && <StoreMarker store={store as any} liveTV />}
        {vehicles.map((vehicle) => (
          <VehicleMarker liveTV key={vehicle.id} vehicle={vehicle as any} />
        ))}
        {store &&
          store.liveTVWaypoints
            .filter((w) => w.isVisible)
            .map((w) => <WaypointMarker key={w.id} waypoint={w} />)}
        <Polyline points={polylinePoints} token={token} authenticated="store" />
      </DynamicMap>
    </div>
  )
}
