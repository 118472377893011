import React, { useMemo, useState } from 'react'

import { Marker, Popup } from 'react-map-gl/maplibre'

import './style.css'
import { SVG, VehicleType } from '../../../utility/SVG'

export interface IVehicleMarker {
  vehicle: {
    id: string
    make: string
    model: string
    type: VehicleType
    color: string
    vehicleCoordinate: {
      id: string
      closestRoadLatitude: number
      closestRoadLongitude: number
      heading: number
    } | null
    driver: {
      id: string
      firstName: string
      lastName: string
      phoneNumber: string
    } | null
  }
  liveTV?: boolean
}

export const VehicleMarker: React.FC<IVehicleMarker> = ({ vehicle, liveTV = false }) => {
  const url = SVG.vehicleMarker(vehicle.type, vehicle.color)
  const [show, setShow] = useState(false)
  const vehicleCoordinate: string | null = vehicle.vehicleCoordinate
    ? JSON.stringify(vehicle.vehicleCoordinate)
    : null
  const vehicleDriver: string | null = vehicle.driver ? JSON.stringify(vehicle.driver) : null

  return useMemo(() => {
    if (!vehicleCoordinate || !vehicleDriver) return null

    const parsedVehicleCoordinate = JSON.parse(vehicleCoordinate)
    const parsedVehicleDriver = JSON.parse(vehicleDriver)

    return (
      <>
        {show && (
          <Popup
            longitude={parsedVehicleCoordinate.closestRoadLongitude}
            latitude={parsedVehicleCoordinate.closestRoadLatitude}
            closeOnClick={false}
            anchor="bottom"
            onClose={() => setShow(false)}
          >
            <p style={{ fontWeight: 'bold', fontSize: 16 }}>
              {parsedVehicleDriver.firstName} {parsedVehicleDriver.lastName}
            </p>
            <p>{parsedVehicleDriver.phoneNumber}</p>
          </Popup>
        )}
        <Marker
          anchor="center"
          longitude={parsedVehicleCoordinate.closestRoadLongitude}
          latitude={parsedVehicleCoordinate.closestRoadLatitude}
          onClick={() => setShow(true)}
        >
          {liveTV && (
            <div
              className="markerLabels"
              style={{
                minWidth: '150px',
                fontSize: liveTV ? '146%' : '126%',
                position: 'absolute',
                transform: `translate(-65px, -16px)`,
              }}
            >
              {parsedVehicleDriver?.firstName}
            </div>
          )}
          <img
            className="mouse-pointer"
            style={{
              width: liveTV ? 22 : 18,
              height: liveTV ? 42 : 38,
              transform: `rotate(${parsedVehicleCoordinate.heading}deg)`,
            }}
            src={url}
            alt={`${vehicle.make} ${vehicle.model}`}
          />
        </Marker>
      </>
    )
  }, [show, vehicle.make, vehicle.model, vehicleDriver, vehicleCoordinate, url, liveTV])
}
