interface LatLngLiteral {
  lat: number
  lng: number
}

export const decodePolyline = (str: string): Array<LatLngLiteral> => {
  let index = 0
  let lat = 0
  let lng = 0
  const coordinates: Array<LatLngLiteral> = []
  let shift = 0
  let result = 0
  let byte: number | null = null
  let latitudeChange: number
  let longitudeChange: number

  while (str && index < str.length) {
    byte = null
    shift = 0
    result = 0

    do {
      byte = str.charCodeAt(index) - 63
      index += 1
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    latitudeChange = result & 1 ? ~(result >> 1) : result >> 1
    shift = 0
    result = 0

    do {
      byte = str.charCodeAt(index) - 63
      index += 1
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    longitudeChange = result & 1 ? ~(result >> 1) : result >> 1

    lat += latitudeChange
    lng += longitudeChange

    coordinates.push({
      lat: lat / 100000,
      lng: lng / 100000,
    })
  }

  return coordinates
}
