import React, { useEffect, useMemo, useState } from 'react'
import { decodePolyline } from '../../../utility'
import { Layer, Source, useMap } from 'react-map-gl/maplibre'
import bbox from '@turf/bbox'
import { lineString } from '@turf/helpers'

export const Polyline: React.FC<{
  points: { latitude: number; longitude: number }[]
  token: string
  authenticated: 'customer' | 'store'
}> = ({ points, token, authenticated }) => {
  const [preview, setPreview] = useState<string | null>(null)
  const { current } = useMap()

  const pointsHash = JSON.stringify(points)

  useEffect(() => {
    if (token && pointsHash !== '[]') {
      const pointsDecoded = JSON.parse(pointsHash)

      fetch(`${window.SC.apiUrl}/v2/mapbox/${authenticated}/route`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          points: pointsDecoded,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.geometry) {
          } else {
            const coordinates: [number, number][] = decodePolyline(json.geometry).map(
              ({ lng, lat }) => [lng, lat],
            )

            setPreview(JSON.stringify({ coordinates }))

            const line = lineString(coordinates)
            const [minLng, minLat, maxLng, maxLat] = bbox(line)

            current?.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              { padding: 80, duration: 1000 },
            )
          }
        })
        .catch(() => {})
    }

    return () => {
      setPreview(null)
    }
  }, [current, pointsHash, token, authenticated])

  return useMemo(() => {
    if (!preview) {
      return null
    }

    const parsedPreview: { coordinates: [number, number][] } = JSON.parse(preview)

    return (
      <Source
        id="previewLayer"
        type="geojson"
        data={{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: parsedPreview.coordinates,
          },
        }}
      >
        <Layer
          id="previewLineLayer"
          type="symbol"
          source="previewLayer"
          layout={{
            'icon-image': 'blue-dot',
            'icon-size': 0.6,
            'symbol-placement': 'line',
            'symbol-spacing': 1,
          }}
        />
      </Source>
    )
  }, [preview])
}
