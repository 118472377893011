import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import 'antd/dist/reset.css'
import 'maplibre-gl/dist/maplibre-gl.css'
import './index.css'

window.SC = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://apiv1.localhost',
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
)
