import React, { useMemo, useState } from 'react'
import { Marker, Popup } from 'react-map-gl/maplibre'
import './style.css'
import { SVG } from '../../../utility/SVG'

export interface IStoreMarker {
  store: {
    id: string
    name: string
    color: string
    place: {
      address: string
      latitude: number
      longitude: number
    }
  }
  liveTV?: boolean
}

export const StoreMarker: React.FC<IStoreMarker> = ({ store, liveTV = false }) => {
  const [show, setShow] = useState(false)

  return useMemo(() => {
    // Create store marker.
    return (
      <>
        {show && (
          <Popup
            longitude={store.place.longitude}
            latitude={store.place.latitude}
            closeOnClick={false}
            anchor="bottom"
            onClose={() => {
              setShow(false)
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 0 }}>{store.name}</p>
            <p style={{ marginBottom: 0, color: 'gray', fontSize: 12 }}>{store.place.address}</p>
          </Popup>
        )}
        <Marker
          anchor="center"
          latitude={store.place.latitude}
          longitude={store.place.longitude}
          onClick={(e) => setShow(true)}
        >
          <div
            className="mouse-pointer"
            style={{ transform: `translate(${(liveTV ? 32 : 25) + store.name.length / 2}%, -20%)` }}
          >
            <div className="marker-label" style={{ fontSize: liveTV ? '146%' : '126%' }}>
              {store.name}
            </div>
            <img
              src={SVG.storeMarker(store.color)}
              alt={store.name}
              className={`${liveTV ? 'large-icon' : ''}`}
            />
          </div>
        </Marker>
      </>
    )
  }, [
    show,
    store.name,
    store.color,
    store.place.address,
    store.place.latitude,
    store.place.longitude,
    liveTV,
  ])
}
