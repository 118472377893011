import React, { CSSProperties } from 'react'
import { Input } from 'antd'

export type Country = 'US' | 'CA' | 'NZ' | 'AU' | 'KZ' | 'ZA' | 'LC'

interface IPhoneNumberInput {
  value?: string
  onChange?: (value: string) => void
  country?: Country
  placeholder?: string
  style?: CSSProperties
  maxLength?: number
}

const formatPhoneNumberForCA = (input: string): string => {
  let result = input.replace(/[^\d]/g, '')

  if (result.length < 7) {
    result = result.replace(/(\d{3})(\d{1,3})/, '$1 $2')
  } else {
    result = result.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1 $2 $3').substring(0, 12)
  }

  return result
}

export const formatPhoneNumberByCountry = (input: string, country: Country): string => {
  let formattedValue = input

  formattedValue = formattedValue.replace(/[^\d]/g, '')

  switch (country) {
    case 'NZ':
      if (formattedValue.length === 1 && formattedValue !== '0') {
        formattedValue = `0${formattedValue}`
      } else if (formattedValue.length < 7) {
        formattedValue = formattedValue.replace(/(\d{3})(\d{1,3})/, '$1 $2')
      } else {
        formattedValue = formattedValue
          .replace(/(\d{3})(\d{3})(\d{1,4})/, '$1 $2 $3')
          .substring(0, 12)
      }
      break
    case 'AU':
      if (formattedValue.length === 1 && formattedValue !== '0') {
        formattedValue = `0${formattedValue}`
      } else if (formattedValue.length < 8) {
        formattedValue = formattedValue.replace(/(\d{4})(\d{1,3})/, '$1 $2')
      } else {
        formattedValue = formattedValue
          .replace(/(\d{4})(\d{3})(\d{1,3})/, '$1 $2 $3')
          .substring(0, 12)
      }

      break
    case 'US':
      formattedValue = formatPhoneNumberForCA(formattedValue)
      break
    case 'CA':
      formattedValue = formatPhoneNumberForCA(formattedValue)
      break
    case 'KZ':
      formattedValue = formatPhoneNumberForCA(formattedValue)
      break
    default:
      formattedValue = formatPhoneNumberForCA(formattedValue)
  }

  return formattedValue
}

export const PhoneNumberInput: React.FC<IPhoneNumberInput> = ({
  value,
  onChange,
  style,
  country = 'CA',
  maxLength = 20,
}) => {
  let placeholder = '555 555 5555'

  switch (country) {
    case 'CA':
      break
    case 'US':
      break
    case 'NZ':
      placeholder = '022 222 2222'
      break
    case 'AU':
      placeholder = '04 4444 4444'
      break
    case 'KZ':
      break
    default:
  }

  return (
    <Input
      value={value}
      maxLength={maxLength}
      onChange={(e) => {
        let formattedValue = formatPhoneNumberByCountry(e.target.value, country)

        if (onChange) {
          onChange(formattedValue)
        }
      }}
      placeholder={placeholder}
      style={style}
    />
  )
}
