import React, { useState, useRef, useEffect } from 'react'
import Map, { MapProvider, MapRef } from 'react-map-gl/maplibre'
import { useNavigate } from 'react-router'
import * as maplibregl from 'maplibre-gl'
import BlueDotPNG from '../../../images/solid-blue-15-dot.png'

interface IDynamicMap {
  center: {
    lat: number
    lng: number
  }
  zoom: number
  // A collection of all coordinates to use when calculating the bounds
  coordinates?: number[][]
  maxZoom?: number
  children?: React.ReactNode
}

interface IMapState {
  center: {
    lat: number
    lng: number
  }
  zoom: number
}

export const DynamicMap: React.FC<IDynamicMap> = ({
  center,
  zoom,
  children,
  maxZoom,
  coordinates = [],
}) => {
  const navigate = useNavigate()
  const mapRef = useRef<MapRef>(null)

  const [state, setState] = useState<IMapState>({ center, zoom })

  const coordinatesHash = JSON.stringify(coordinates)

  useEffect(
    () => setState({ center: { lng: center.lng, lat: center.lat }, zoom }),
    [center.lng, center.lat, zoom],
  )

  useEffect(() => {
    const coordinatesParsed: number[][] = JSON.parse(coordinatesHash)

    // We need at least 2 coordinates to build the bounding box
    if (coordinatesParsed.length > 1) {
      // Map the coordinate array into individual lat and lng arrays
      const lat = coordinatesParsed.map((coord: any) => coord[0] ?? 0)
      const lng = coordinatesParsed.map((coord: any) => coord[1] ?? 0)

      // Get the min and max of each coordinate to get the bounds we should use as the view
      const outerBounds = [
        [Math.min.apply(null, lng), Math.min.apply(null, lat)],
        [Math.max.apply(null, lng), Math.max.apply(null, lat)],
      ] as maplibregl.LngLatBoundsLike

      // Adjust the map to fit our newly calculated bounds
      mapRef.current?.fitBounds(outerBounds, { padding: 200, duration: 200 })
    }
  }, [coordinatesHash])

  return (
    <MapProvider>
      <Map
        ref={mapRef}
        longitude={state.center.lng}
        latitude={state.center.lat}
        zoom={state.zoom}
        maxZoom={maxZoom}
        maxPitch={0}
        mapStyle={`https://api.maptiler.com/maps/7e06514a-7eb2-4a58-ad53-12a3ec9e4a97/style.json?key=${process
          .env.REACT_APP_MAPTILER_API_KEY!}`}
        reuseMaps
        onMove={(evt) => {
          setState({
            center: { lng: evt.viewState.longitude, lat: evt.viewState.latitude },
            zoom: evt.viewState.zoom,
          })
        }}
        onMoveEnd={(evt) => {
          navigate(
            `${window.location.pathname}?latitude=${evt.viewState.latitude}&longitude=${evt.viewState.longitude}&zoom=${evt.viewState.zoom}`,
          )
        }}
        onLoad={async (e) => {
          const map = mapRef.current

          if (map) {
            e.target.scrollZoom.setWheelZoomRate(1)

            if (!e.target.hasImage('blue-dot')) {
              const response = await e.target.loadImage(BlueDotPNG)

              e.target.addImage('blue-dot', response.data)
            }

            e.target.dragRotate.disable() // disable map rotation using right click + drag
            e.target.keyboard.disable() // disable map rotation using keyboard
            e.target.touchZoomRotate.disableRotation() // disable map rotation using touch rotation gesture
          }
        }}
      >
        {children}
      </Map>
    </MapProvider>
  )
}
