import React, { useState, useEffect } from 'react'
import { Select, message, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option, OptGroup } = Select

interface IReturnTripWorkflowSelector {
  noDefaultReturnTripWorkflowOption: boolean
  workflowId?: string
  value?: string
  onChange?: (workflowId: string) => void
  placeholder?: string
  disabled?: boolean
  size?: 'small' | 'middle' | 'large'
  token: string
  enabledWorkflows: string[]
}

interface IStore {
  id: string
  name: string
  workflows: {
    id: string
    name: string
  }[]
}

export const ReturnTripWorkflowSelector: React.FC<IReturnTripWorkflowSelector> = ({
  noDefaultReturnTripWorkflowOption,
  workflowId,
  value,
  onChange,
  placeholder,
  disabled,
  size,
  token,
  enabledWorkflows,
}) => {
  const [stores, setStores] = useState<IStore[] | null>(null)
  const [t] = useTranslation()

  useEffect(() => {
    fetch(`${window.SC.apiUrl}/v2/workflows/returnTrip/${workflowId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json: any) => {
        if (json.error) {
          message.error(t(`error.server.${json.error.message}`))
        } else {
          setStores(json.items)
        }
      })
      .catch(() => {})
  }, [workflowId, token, t])

  if (stores === null) {
    return <Spin />
  }

  return (
    <Select
      data-cy="return-trip-workflow-selector"
      placeholder={placeholder}
      style={{ width: '100%' }}
      value={value}
      filterOption={(inputValue, option) => {
        if (option) {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }

        return false
      }}
      onChange={onChange}
      disabled={disabled}
      size={size}
    >
      {[
        ...(noDefaultReturnTripWorkflowOption
          ? [
              <Option key="none" value="none">
                {t('workflows.noDefaultReturnTripWorkflow')}
              </Option>,
            ]
          : []),
        ...stores.map((store) => (
          <OptGroup label={store.name} key={store.id}>
            {store.workflows
              .filter((w) => enabledWorkflows.includes(w.id))
              .map((workflow) => (
                <Option data-cy="return-trip-workflow-option" key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </Option>
              ))}
          </OptGroup>
        )),
      ]}
    </Select>
  )
}
