export type VehicleType = 'CAR' | 'VAN' | 'BUS' | 'BICYCLE'

export class SVG {
  static dataUrl = (svg: string): string => `data:image/svg+xml;base64,${btoa(svg)}`

  static vehicleMarker = (type: VehicleType, bodyColor: string) =>
    type === 'VAN' ? SVG.vehicleMarkerVan(bodyColor) : SVG.vehicleMarkerSedan(bodyColor)

  static vehicleMarkerVan = (bodyColor: string) => {
    const showOutline = bodyColor === 'white'

    return SVG.dataUrl(`<?xml version="1.0" encoding="utf-8"?>
      <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        ${!showOutline ? 'viewBox="0 0 23.2 50" enable-background="new 0 0 23.2 50"' : ''}
        ${
          /* If there is an outline, increase the viewBox size and shift the graphic to center it */ ''
        }
        ${showOutline ? 'viewBox="0 -0.5 23.2 51" enable-background="new 0 -0.5 23.2 51"' : ''}
         xml:space="preserve">
      <g id="XMLID_156_">
        <path id="XMLID_368_" fill-rule="evenodd" clip-rule="evenodd" d="M0.3,10.9L2,10.5l1.2-2.1L3,11.6l-0.8-0.2l-2.1,0.6L0,11.5
          C0,11.2,0.1,11,0.3,10.9z"/>
        <path id="XMLID_367_" fill-rule="evenodd" clip-rule="evenodd" d="M22.9,10.9l-1.6-0.4L20,8.4l0.2,3.2l0.8-0.2l2.1,0.6l0.1-0.4
          C23.3,11.2,23.1,11,22.9,10.9z"/>
        <g id="XMLID_340_" ${showOutline ? 'stroke="black" stroke-width="1"' : ''}>
          <path id="XMLID_134_" fill-rule="evenodd" clip-rule="evenodd" fill="#A6A8AB" d="M7.3,0.6C7.1,0.7,6.8,1,6.6,1.1
            c-1.2,0.6-1.8,1-2.2,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.8,7.5-0.8,7.5s0,33.9,0,34.7c0,1.2,0.8,1.6,1.9,1.9
            c1.6,0.4,4.4,0.6,7.1,0.6c2.7,0,5.5-0.2,7.1-0.6c1-0.3,1.9-0.7,1.9-1.9c0-0.8,0-34.7,0-34.7S20,6.2,19.8,5.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1-1-2.2-1.6c-0.2-0.1-0.4-0.5-0.7-0.6C15,0.2,13.3,0,11.6,0C9.9,0,8.2,0.2,7.3,0.6z"/>
          <path id="XMLID_154_" fill-rule="evenodd" clip-rule="evenodd" fill="#A7A9AC" d="M7.3,0.6C7.1,0.7,6.9,1.1,6.6,1.2
            c-1.2,0.6-1.8,1-2.3,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.8,7.5-0.8,7.5s0,33.9,0,34.7c0,1.2,0.8,1.6,1.9,1.9
            c1.6,0.4,4.4,0.6,7.1,0.6c2.7,0,5.5-0.2,7.1-0.6c1-0.3,1.9-0.7,1.9-1.9c0-0.8,0-34.7,0-34.7S20,6.2,19.8,5.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6C15,0.2,13.3,0,11.6,0C9.9,0,8.2,0.2,7.3,0.6z"/>
          <path id="XMLID_153_" fill-rule="evenodd" clip-rule="evenodd" fill="#A8AAAD" d="M7.4,0.6C7.1,0.7,6.9,1.1,6.7,1.2
            c-1.2,0.6-1.8,1-2.3,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.7,7.5-0.7,7.5s0,33.9,0,34.7c0,1.2,0.8,1.6,1.9,1.9
            c1.6,0.4,4.4,0.6,7.1,0.6c2.7,0,5.5-0.2,7.1-0.6c1-0.3,1.9-0.7,1.9-1.9c0-0.8,0-34.7,0-34.7S20,6.2,19.8,5.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6C15,0.3,13.3,0,11.6,0C9.9,0,8.3,0.3,7.4,0.6z"/>
          <path id="XMLID_152_" fill-rule="evenodd" clip-rule="evenodd" fill="#A9ABAE" d="M7.4,0.6C7.1,0.7,6.9,1.1,6.7,1.2
            c-1.2,0.6-1.8,1-2.3,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.7,7.5-0.7,7.5s0,33.9,0,34.7c0,1.2,0.8,1.6,1.9,1.9
            c1.6,0.4,4.4,0.6,7.1,0.6c2.7,0,5.5-0.2,7.1-0.6c1-0.3,1.9-0.7,1.9-1.9c0-0.8,0-34.7,0-34.7S20,6.2,19.8,5.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.1,8.3,0.3,7.4,0.6z"/>
          <path id="XMLID_151_" fill-rule="evenodd" clip-rule="evenodd" fill="#AAACAF" d="M7.4,0.6C7.1,0.7,6.9,1.1,6.7,1.2
            c-1.2,0.6-1.8,1-2.3,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.7,7.4-0.7,7.4s0,33.9,0,34.7c0,1.2,0.8,1.6,1.9,1.9
            c1.6,0.4,4.3,0.6,7,0.6c2.7,0,5.4-0.2,7-0.6c1-0.3,1.9-0.7,1.9-1.9c0-0.8,0-34.7,0-34.7s-0.6-6.5-0.7-7.4c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.1,8.3,0.3,7.4,0.6z"/>
          <path id="XMLID_150_" fill-rule="evenodd" clip-rule="evenodd" fill="#ABADB0" d="M7.4,0.7C7.1,0.8,6.9,1.1,6.7,1.2
            c-1.2,0.6-1.9,1-2.3,1.6c-0.5,0.8-0.9,1.6-1,2.5c-0.1,0.9-0.7,7.4-0.7,7.4s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            c1.6,0.4,4.3,0.6,7,0.6c2.7,0,5.4-0.2,7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.6-6.5-0.7-7.4c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.1,8.3,0.3,7.4,0.7z"/>
          <path id="XMLID_149_" fill-rule="evenodd" clip-rule="evenodd" fill="#ACAEB1" d="M7.4,0.7C7.2,0.8,6.9,1.2,6.7,1.3
            c-1.2,0.6-1.9,1-2.3,1.6c-0.5,0.8-0.8,1.6-1,2.5c-0.1,0.9-0.7,7.4-0.7,7.4s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            c1.6,0.4,4.3,0.6,7,0.6c2.7,0,5.4-0.2,7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.5-0.7-7.4c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.1,8.3,0.3,7.4,0.7z"/>
          <path id="XMLID_148_" fill-rule="evenodd" clip-rule="evenodd" fill="#ADAFB2" d="M7.4,0.7C7.2,0.8,7,1.2,6.7,1.3
            c-1.2,0.6-1.9,1-2.3,1.6C4,3.7,3.6,4.5,3.5,5.4c-0.1,0.9-0.7,7.4-0.7,7.4s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            c1.6,0.4,4.3,0.6,7,0.6c2.7,0,5.4-0.2,7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.5-0.7-7.4c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.2,8.3,0.4,7.4,0.7z"/>
          <path id="XMLID_147_" fill-rule="evenodd" clip-rule="evenodd" fill="#AEB0B3" d="M7.4,0.7C7.2,0.8,7,1.2,6.8,1.3
            c-1.2,0.6-1.9,1-2.3,1.6C4,3.7,3.6,4.5,3.5,5.4c-0.1,0.9-0.7,7.4-0.7,7.4s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            c1.6,0.4,4.3,0.6,6.9,0.6c2.7,0,5.4-0.2,6.9-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.5-0.7-7.4
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.2-0.6C9.9,0.2,8.3,0.4,7.4,0.7z"/>
          <path id="XMLID_146_" fill-rule="evenodd" clip-rule="evenodd" fill="#AFB1B4" d="M7.5,0.7C7.2,0.8,7,1.2,6.8,1.3
            c-1.2,0.6-1.9,1-2.3,1.6C4,3.7,3.6,4.6,3.5,5.5c-0.1,0.9-0.6,7.3-0.6,7.3s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            c1.6,0.4,4.3,0.6,6.9,0.6c2.7,0,5.4-0.2,6.9-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.4-0.6-7.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.1-0.6C10,0.2,8.3,0.4,7.5,0.7z"/>
          <path id="XMLID_145_" fill-rule="evenodd" clip-rule="evenodd" fill="#B0B2B5" d="M7.5,0.8C7.2,0.9,7,1.2,6.8,1.3
            C5.6,1.9,4.9,2.3,4.5,3C4,3.7,3.7,4.6,3.5,5.5c-0.1,0.9-0.6,7.3-0.6,7.3s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            C6.3,49.8,9,50,11.6,50c2.6,0,5.3-0.2,6.9-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.4-0.6-7.3
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.1-0.6C10,0.2,8.3,0.4,7.5,0.8z"/>
          <path id="XMLID_144_" fill-rule="evenodd" clip-rule="evenodd" fill="#B1B3B5" d="M7.5,0.8C7.2,0.9,7,1.3,6.8,1.4
            C5.6,2,4.9,2.3,4.5,3C4,3.8,3.7,4.6,3.5,5.5c-0.1,0.9-0.6,7.3-0.6,7.3s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9C6.3,49.8,9,50,11.6,50
            c2.6,0,5.3-0.2,6.9-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.5-6.4-0.6-7.3c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.1-0.6C10,0.2,8.4,0.4,7.5,0.8z"/>
          <path id="XMLID_143_" fill-rule="evenodd" clip-rule="evenodd" fill="#B2B4B6" d="M7.5,0.8C7.2,0.9,7,1.3,6.8,1.4
            C5.7,2,4.9,2.4,4.5,3C4,3.8,3.7,4.6,3.5,5.5C3.4,6.4,3,12.8,3,12.8s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9C6.3,49.8,9,50,11.6,50
            c2.6,0,5.3-0.2,6.8-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.4-0.6-7.3c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.1-0.6C10,0.3,8.4,0.5,7.5,0.8z"/>
          <path id="XMLID_142_" fill-rule="evenodd" clip-rule="evenodd" fill="#B3B5B7" d="M7.5,0.8C7.3,0.9,7.1,1.3,6.8,1.4
            C5.7,2,4.9,2.4,4.5,3C4,3.8,3.7,4.6,3.6,5.5C3.4,6.4,3,12.8,3,12.8s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9C6.3,49.8,9,50,11.6,50
            c2.6,0,5.3-0.2,6.8-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.4-0.6-7.3c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.1-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.5-0.5-4.1-0.6C10,0.3,8.4,0.5,7.5,0.8z"/>
          <path id="XMLID_141_" fill-rule="evenodd" clip-rule="evenodd" fill="#B4B6B8" d="M7.5,0.8C7.3,0.9,7.1,1.3,6.9,1.4
            C5.7,2,4.9,2.4,4.5,3.1c-0.5,0.8-0.8,1.6-1,2.5C3.4,6.5,3,12.8,3,12.8s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9C6.3,49.8,9,50,11.6,50
            c2.6,0,5.3-0.2,6.8-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.3-0.6-7.2c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.4-0.5-4.1-0.6C10,0.3,8.4,0.5,7.5,0.8z"/>
          <path id="XMLID_140_" fill-rule="evenodd" clip-rule="evenodd" fill="#B5B7B9" d="M7.6,0.9C7.3,1,7.1,1.3,6.9,1.4
            C5.7,2,5,2.4,4.6,3.1c-0.5,0.8-0.8,1.6-1,2.5C3.4,6.5,3,12.8,3,12.8s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9C6.4,49.8,9,50,11.6,50
            c2.6,0,5.2-0.2,6.8-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.3-0.5-7.2c-0.1-0.9-0.5-1.7-1-2.5
            c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.9-0.3-2.4-0.5-4.1-0.6C10,0.3,8.4,0.5,7.6,0.9z"/>
          <path id="XMLID_139_" fill-rule="evenodd" clip-rule="evenodd" fill="#B6B8BA" d="M7.6,0.9C7.3,1,7.1,1.4,6.9,1.5
            C5.7,2.1,5,2.4,4.6,3.1c-0.5,0.8-0.8,1.6-1,2.5c-0.1,0.9-0.5,7.2-0.5,7.2s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            C6.4,49.8,9,50,11.6,50c2.6,0,5.2-0.2,6.8-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.3-0.5-7.2
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.8-0.3-2.4-0.5-4-0.6C10,0.3,8.4,0.5,7.6,0.9z"/>
          <path id="XMLID_138_" fill-rule="evenodd" clip-rule="evenodd" fill="#B7B9BB" d="M7.6,0.9C7.3,1,7.1,1.4,6.9,1.5
            C5.8,2.1,5,2.5,4.6,3.1c-0.5,0.8-0.8,1.6-1,2.5c-0.1,0.9-0.5,7.2-0.5,7.2s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            C6.4,49.8,9,50,11.6,50c2.6,0,5.2-0.2,6.7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.3-0.5-7.2
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.8-0.3-2.4-0.5-4-0.6C10,0.4,8.4,0.6,7.6,0.9z"/>
          <path id="XMLID_137_" fill-rule="evenodd" clip-rule="evenodd" fill="#B8BABC" d="M7.6,0.9C7.3,1,7.1,1.4,6.9,1.5
            C5.8,2.1,5,2.5,4.6,3.1c-0.5,0.8-0.8,1.6-1,2.5c-0.1,0.9-0.5,7.2-0.5,7.2s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            C6.4,49.8,9,50,11.6,50c2.6,0,5.2-0.2,6.7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.4-6.3-0.5-7.2
            c-0.1-0.9-0.5-1.7-1-2.5c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.8-0.3-2.4-0.5-4-0.6C10,0.4,8.4,0.6,7.6,0.9z"/>
          <path id="XMLID_136_" fill-rule="evenodd" clip-rule="evenodd" fill="#B9BBBD" d="M7.6,0.9C7.4,1,7.2,1.4,6.9,1.5
            C5.8,2.1,5,2.5,4.6,3.2C4.1,3.9,3.8,4.8,3.7,5.7c-0.1,0.9-0.5,7.1-0.5,7.1s0,33.9,0,34.7c0,1.2,0.8,1.6,1.8,1.9
            C6.4,49.8,9,50,11.6,50c2.6,0,5.2-0.2,6.7-0.6c1-0.3,1.8-0.7,1.8-1.9c0-0.8,0-34.7,0-34.7s-0.3-6.2-0.5-7.1
            c-0.1-0.9-0.5-1.7-0.9-2.5c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.7-0.6c-0.8-0.3-2.4-0.5-4-0.6C10,0.4,8.4,0.6,7.6,0.9z"/>
          <path id="XMLID_135_" fill-rule="evenodd" clip-rule="evenodd" fill="#BABCBE" d="M7.6,1C7.4,1.1,7.2,1.4,7,1.6
            c-1.1,0.6-2,1-2.4,1.6C4.1,4,3.8,4.8,3.7,5.7c-0.1,0.9-0.5,7.1-0.5,7.1s0,33.9,0,34.7c0,1.2,0.8,1.6,1.7,1.9
            c1.5,0.4,4.1,0.6,6.7,0.6c2.6,0,5.1-0.2,6.7-0.6c1-0.3,1.7-0.7,1.7-1.9c0-0.8,0-34.7,0-34.7s-0.3-6.2-0.5-7.1
            c-0.1-0.9-0.5-1.7-0.9-2.5c-0.4-0.7-1.2-1-2.4-1.6C16,1.4,15.8,1.1,15.6,1c-0.8-0.3-2.4-0.5-4-0.6C10,0.4,8.5,0.6,7.6,1z"/>
          <path id="XMLID_133_" fill-rule="evenodd" clip-rule="evenodd" fill="#BBBDBF" d="M7.6,1C7.4,1.1,7.2,1.5,7,1.6
            c-1.1,0.6-2,1-2.4,1.6C4.2,4,3.8,4.8,3.7,5.7c-0.1,0.9-0.4,7.1-0.4,7.1s0,33.9,0,34.7c0,1.2,0.8,1.6,1.7,1.9
            c1.5,0.4,4.1,0.6,6.6,0.6c2.5,0,5.1-0.2,6.6-0.6c1-0.3,1.7-0.7,1.7-1.9c0-0.8,0-34.7,0-34.7s-0.3-6.2-0.4-7.1
            c-0.1-0.9-0.5-1.7-0.9-2.5c-0.4-0.7-1.2-1-2.4-1.6C16,1.5,15.8,1.1,15.6,1c-0.8-0.3-2.4-0.5-4-0.6C10,0.4,8.5,0.6,7.6,1z"/>
        </g>
        <path id="XMLID_339_" fill-rule="evenodd" clip-rule="evenodd" d="M7.9,0.8c-0.1,0-0.1,0.1-0.2,0.1v0.6h7.8V0.9
          c-0.1-0.1-0.1-0.1-0.2-0.1c-0.8-0.3-2.2-0.5-3.7-0.6C10.1,0.2,8.7,0.4,7.9,0.8z"/>
        <path id="XMLID_338_" fill-rule="evenodd" clip-rule="evenodd" fill="#808184" d="M6.7,1.4v1.5L4.6,4l-0.1-1C4.9,2.3,5.5,2,6.7,1.4
          C6.6,1.4,6.7,1.4,6.7,1.4L6.7,1.4z"/>
        <path id="XMLID_337_" fill-rule="evenodd" clip-rule="evenodd" fill="#808184" d="M16.5,1.4v1.5L18.6,4l0.1-1
          C18.3,2.3,17.7,2,16.5,1.4C16.6,1.4,16.5,1.4,16.5,1.4L16.5,1.4z"/>
        <path id="SVG_x5F_BODY_x5F_COLOR" fill-rule="evenodd" clip-rule="evenodd" fill="{{bodyColor}}" d="M19.3,5.7c-0.1-0.9-0.5-1.7-0.9-2.5
          c-0.4-0.7-1.2-1-2.3-1.6c-0.2-0.1-0.4-0.5-0.6-0.6c-0.8-0.3-2.3-0.5-3.9-0.6c-1.5,0-3.1,0.2-3.9,0.6C7.5,1.1,7.3,1.5,7.1,1.6
          C6,2.2,5.2,2.5,4.8,3.2C4.3,4,4,4.8,3.9,5.7c-0.1,0.9-0.7,6.8-0.7,6.8s0.2,34.2,0.2,35c0,1.2,0.8,1.6,1.7,1.9
          c1.5,0.4,4,0.6,6.5,0.6c2.5,0,5-0.2,6.5-0.6c0.9-0.3,1.7-0.7,1.7-1.9c0-0.8,0.2-35,0.2-35S19.5,6.6,19.3,5.7z"/>
        <path id="XMLID_335_" fill-rule="evenodd" clip-rule="evenodd" d="M20.2,8.8l0.1,4.3v4h0.3v-4.3C20.6,12.8,20.4,10.7,20.2,8.8
          L20.2,8.8z"/>
        <path id="XMLID_334_" fill-rule="evenodd" clip-rule="evenodd" d="M3,8.8l-0.1,4.3v4H2.6v-4.3C2.6,12.8,2.8,10.7,3,8.8L3,8.8z"/>
        <path id="XMLID_333_" fill-rule="evenodd" clip-rule="evenodd" fill="#808184" d="M4.7,3.3C4.2,4.2,4,5,3.9,5.8
          c-0.1,0.8-0.1,1.6,0,2.6l-0.1,0c-0.1-0.9-0.1-1.8,0-2.6C3.9,5,4.2,4.2,4.7,3.3L4.7,3.3z"/>
        <path id="XMLID_332_" fill-rule="evenodd" clip-rule="evenodd" fill="#808184" d="M18.5,3.3C19,4.2,19.2,5,19.3,5.8
          c0.1,0.8,0.1,1.6,0,2.6l0.1,0c0.1-0.9,0.1-1.8,0-2.6C19.3,5,19.1,4.2,18.5,3.3L18.5,3.3z"/>
        <linearGradient id="XMLID_214_" gradientUnits="userSpaceOnUse" x1="5.7767" y1="3.1949" x2="5.0344" y2="-2.037596e-002">
          <stop  offset="0" style="stop-color:#929497"/>
          <stop  offset="1" style="stop-color:#E6E7E8"/>
        </linearGradient>
        <path id="XMLID_331_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_214_)" d="M6.6,1.5v0.2c-0.9,0.5-1.6,1-2,1.5
          c0,0,0,0,0,0.1l0-0.4C4.9,2.5,5.5,2.1,6.6,1.5C6.6,1.6,6.6,1.5,6.6,1.5L6.6,1.5z"/>
        <linearGradient id="XMLID_215_" gradientUnits="userSpaceOnUse" x1="17.417" y1="3.1936" x2="18.2153" y2="-8.266404e-003">
          <stop  offset="0" style="stop-color:#929497"/>
          <stop  offset="1" style="stop-color:#E6E7E8"/>
        </linearGradient>
        <path id="XMLID_330_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_215_)" d="M16.6,1.5v0.2c0.9,0.5,1.6,1,2,1.5
          c0,0,0,0,0,0.1l0-0.4C18.3,2.5,17.7,2.1,16.6,1.5C16.6,1.6,16.6,1.5,16.6,1.5L16.6,1.5z"/>
        <path id="XMLID_329_" fill-rule="evenodd" clip-rule="evenodd" d="M5.1,12.8C3,13.1,3.2,13,3.2,11.6c0.1-1.4,0.3-3.5,0.6-4.1
          C4.1,7,6.5,6.4,7.6,6.2c3-0.4,5.1-0.4,8.1,0c1.1,0.1,3.5,0.8,3.7,1.3c0.2,0.5,0.5,2.7,0.6,4.1c0.1,1.4,0.3,1.5-1.9,1.2
          C14.1,12.2,9.1,12.2,5.1,12.8z"/>
        <path id="XMLID_328_" fill-rule="evenodd" clip-rule="evenodd" d="M3.8,7.5c0.5-1,1.1-1.5,1.8-1.9c2.4-1.3,9.5-1.3,11.9,0
          c0.7,0.4,1.3,1,1.8,1.9h-7.8H3.8z"/>
        <path id="XMLID_327_" fill-rule="evenodd" clip-rule="evenodd" fill="#0D383D" d="M5.3,12.7c-2.1,0.3-1.9,0.2-1.8-1.2
          C3.6,10.4,3.8,8.8,4,8c0.6-0.5,2.6-1,3.6-1.1c3-0.4,5.1-0.4,8.1,0c1,0.1,3,0.6,3.6,1.1c0.2,0.8,0.4,2.4,0.4,3.5
          c0.1,1.4,0.3,1.5-1.8,1.2C14,12.1,9.2,12.1,5.3,12.7z"/>
        <g id="XMLID_300_" opacity="0.5">
          <path id="XMLID_112_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.8L4.7,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C3.9,19.2,4.3,18.8,4.7,18.8z"/>
          <path id="XMLID_132_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.8L4.7,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.4,0-0.8-0.4-0.8-0.9V19.6C3.9,19.2,4.3,18.8,4.7,18.8z"/>
          <path id="XMLID_131_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.8L4.7,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8l0,0c-0.4,0-0.8-0.4-0.8-0.8V19.6C3.9,19.2,4.3,18.8,4.7,18.8z"/>
          <path id="XMLID_130_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.8L4.7,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8l0,0c-0.4,0-0.8-0.4-0.8-0.8V19.7C4,19.2,4.3,18.8,4.7,18.8z"/>
          <path id="XMLID_129_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.9L4.7,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8l0,0c-0.4,0-0.7-0.4-0.7-0.8V19.7C4,19.2,4.3,18.9,4.7,18.9z"/>
          <path id="XMLID_128_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.9L4.7,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8l0,0c-0.4,0-0.7-0.3-0.7-0.8V19.7C4,19.2,4.3,18.9,4.7,18.9z"/>
          <path id="XMLID_127_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.9L4.7,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C4,19.2,4.3,18.9,4.7,18.9z"/>
          <path id="XMLID_126_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,18.9L4.7,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C4,19.3,4.3,18.9,4.7,18.9z"/>
          <path id="XMLID_125_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19L4.7,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C4.1,19.3,4.4,19,4.7,19z"/>
          <path id="XMLID_124_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19L4.7,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7l0,0c-0.3,0-0.6-0.3-0.6-0.7V19.7C4.1,19.3,4.4,19,4.7,19z"/>
          <path id="XMLID_123_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19L4.7,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7l0,0c-0.3,0-0.6-0.3-0.6-0.7V19.7C4.1,19.3,4.4,19,4.7,19z"/>
          <path id="XMLID_122_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19L4.7,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6l0,0c-0.3,0-0.6-0.3-0.6-0.6V19.7C4.1,19.3,4.4,19,4.7,19z"/>
          <path id="XMLID_121_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.1L4.7,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            C5.3,47.8,5,48,4.7,48l0,0c-0.3,0-0.6-0.3-0.6-0.6V19.7C4.2,19.3,4.4,19.1,4.7,19.1z"/>
          <path id="XMLID_120_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.1L4.7,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            C5.3,47.7,5,48,4.7,48l0,0c-0.3,0-0.5-0.3-0.5-0.6V19.7C4.2,19.4,4.4,19.1,4.7,19.1z"/>
          <path id="XMLID_119_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.1L4.7,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            C5.2,47.7,5,48,4.7,48l0,0c-0.3,0-0.5-0.3-0.5-0.6V19.7C4.2,19.4,4.4,19.1,4.7,19.1z"/>
          <path id="XMLID_118_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.2L4.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C4.2,19.4,4.4,19.2,4.7,19.2z"/>
          <path id="XMLID_117_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.2L4.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C4.2,19.4,4.5,19.2,4.7,19.2z"/>
          <path id="XMLID_116_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.2L4.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C4.3,19.4,4.5,19.2,4.7,19.2z"/>
          <path id="XMLID_115_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.2L4.7,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5l0,0c-0.2,0-0.4-0.2-0.4-0.5V19.7C4.3,19.4,4.5,19.2,4.7,19.2z"/>
          <path id="XMLID_114_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.3L4.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C4.3,19.5,4.5,19.3,4.7,19.3z"/>
          <path id="XMLID_113_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.3L4.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C4.3,19.5,4.5,19.3,4.7,19.3z"/>
          <path id="XMLID_111_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M4.7,19.3L4.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C4.4,19.5,4.5,19.3,4.7,19.3z"/>
        </g>
        <g id="XMLID_273_" opacity="0.5">
          <path id="XMLID_90_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.8L7.5,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C6.6,19.2,7,18.8,7.5,18.8z"/>
          <path id="XMLID_110_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.8L7.5,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.4,0-0.8-0.4-0.8-0.9V19.6C6.7,19.2,7,18.8,7.5,18.8z"/>
          <path id="XMLID_109_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.8L7.5,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8l0,0c-0.4,0-0.8-0.4-0.8-0.8V19.6C6.7,19.2,7,18.8,7.5,18.8z"/>
          <path id="XMLID_108_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.8L7.5,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8l0,0c-0.4,0-0.8-0.4-0.8-0.8V19.7C6.7,19.2,7.1,18.8,7.5,18.8z"/>
          <path id="XMLID_107_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.9L7.5,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8l0,0c-0.4,0-0.7-0.4-0.7-0.8V19.7C6.7,19.2,7.1,18.9,7.5,18.9z"/>
          <path id="XMLID_106_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.9L7.5,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8l0,0c-0.4,0-0.7-0.3-0.7-0.8V19.7C6.8,19.2,7.1,18.9,7.5,18.9z"/>
          <path id="XMLID_105_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.9L7.5,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C6.8,19.2,7.1,18.9,7.5,18.9z"/>
          <path id="XMLID_104_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,18.9L7.5,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C6.8,19.3,7.1,18.9,7.5,18.9z"/>
          <path id="XMLID_103_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19L7.5,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7V19.7C6.8,19.3,7.1,19,7.5,19z"/>
          <path id="XMLID_102_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19L7.5,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7l0,0c-0.3,0-0.6-0.3-0.6-0.7V19.7C6.8,19.3,7.1,19,7.5,19z"/>
          <path id="XMLID_101_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19L7.5,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7l0,0c-0.3,0-0.6-0.3-0.6-0.7V19.7C6.9,19.3,7.1,19,7.5,19z"/>
          <path id="XMLID_100_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19L7.5,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6l0,0c-0.3,0-0.6-0.3-0.6-0.6V19.7C6.9,19.3,7.2,19,7.5,19z"/>
          <path id="XMLID_99_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.1L7.5,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            C8,47.8,7.8,48,7.5,48l0,0c-0.3,0-0.6-0.3-0.6-0.6V19.7C6.9,19.3,7.2,19.1,7.5,19.1z"/>
          <path id="XMLID_98_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.1L7.5,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            C8,47.7,7.8,48,7.5,48l0,0c-0.3,0-0.5-0.3-0.5-0.6V19.7C6.9,19.4,7.2,19.1,7.5,19.1z"/>
          <path id="XMLID_97_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.1L7.5,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            C8,47.7,7.8,48,7.5,48l0,0C7.2,48,7,47.7,7,47.4V19.7C7,19.4,7.2,19.1,7.5,19.1z"/>
          <path id="XMLID_96_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.2L7.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C7,19.4,7.2,19.2,7.5,19.2z"/>
          <path id="XMLID_95_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.2L7.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C7,19.4,7.2,19.2,7.5,19.2z"/>
          <path id="XMLID_94_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.2L7.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V19.7C7,19.4,7.2,19.2,7.5,19.2z"/>
          <path id="XMLID_93_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.2L7.5,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5l0,0c-0.2,0-0.4-0.2-0.4-0.5V19.7C7,19.4,7.2,19.2,7.5,19.2z"/>
          <path id="XMLID_92_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.3L7.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C7.1,19.5,7.2,19.3,7.5,19.3z"/>
          <path id="XMLID_91_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.3L7.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C7.1,19.5,7.3,19.3,7.5,19.3z"/>
          <path id="XMLID_89_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M7.5,19.3L7.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4V19.7C7.1,19.5,7.3,19.3,7.5,19.3z"/>
        </g>
        <g id="XMLID_246_" opacity="0.5">
          <path id="XMLID_68_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.8L13,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C12.1,19.2,12.5,18.8,13,18.8z"/>
          <path id="XMLID_88_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.8L13,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9h0c-0.4,0-0.8-0.4-0.8-0.9V19.6C12.2,19.2,12.5,18.8,13,18.8z"/>
          <path id="XMLID_87_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.8L13,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.6C12.2,19.2,12.5,18.8,13,18.8z"/>
          <path id="XMLID_86_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.8L13,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.7C12.2,19.2,12.6,18.8,13,18.8z"/>
          <path id="XMLID_85_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.9L13,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.4-0.7-0.8V19.7C12.2,19.2,12.6,18.9,13,18.9z"/>
          <path id="XMLID_84_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.9L13,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.3-0.7-0.8V19.7C12.3,19.2,12.6,18.9,13,18.9z"/>
          <path id="XMLID_83_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.9L13,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C12.3,19.2,12.6,18.9,13,18.9z"/>
          <path id="XMLID_82_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,18.9L13,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C12.3,19.3,12.6,18.9,13,18.9z"/>
          <path id="XMLID_81_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19L13,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C12.3,19.3,12.6,19,13,19z"/>
          <path id="XMLID_80_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19L13,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C12.3,19.3,12.6,19,13,19z"/>
          <path id="XMLID_79_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19L13,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C12.4,19.3,12.6,19,13,19z"/>
          <path id="XMLID_78_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19L13,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C12.4,19.3,12.7,19,13,19z"/>
          <path id="XMLID_77_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.1L13,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C12.4,19.3,12.7,19.1,13,19.1z"/>
          <path id="XMLID_76_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.1L13,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C12.4,19.4,12.7,19.1,13,19.1z"/>
          <path id="XMLID_75_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.1L13,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C12.5,19.4,12.7,19.1,13,19.1z"/>
          <path id="XMLID_74_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.2L13,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C12.5,19.4,12.7,19.2,13,19.2z"/>
          <path id="XMLID_73_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.2L13,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C12.5,19.4,12.7,19.2,13,19.2z"/>
          <path id="XMLID_72_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.2L13,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C12.5,19.4,12.7,19.2,13,19.2z"/>
          <path id="XMLID_71_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.2L13,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5h0c-0.2,0-0.4-0.2-0.4-0.5V19.7C12.5,19.4,12.7,19.2,13,19.2z"/>
          <path id="XMLID_70_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.3L13,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C12.6,19.5,12.8,19.3,13,19.3z"/>
          <path id="XMLID_69_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.3L13,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C12.6,19.5,12.8,19.3,13,19.3z"/>
          <path id="XMLID_67_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13,19.3L13,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C12.6,19.5,12.8,19.3,13,19.3z"/>
        </g>
        <g id="XMLID_219_" opacity="0.5">
          <path id="XMLID_46_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.8L10.3,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C9.5,19.2,9.9,18.8,10.3,18.8z"/>
          <path id="XMLID_66_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.8L10.3,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9h0c-0.4,0-0.8-0.4-0.8-0.9V19.6C9.5,19.2,9.9,18.8,10.3,18.8z"/>
          <path id="XMLID_65_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.8L10.3,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.6C9.5,19.2,9.9,18.8,10.3,18.8z"/>
          <path id="XMLID_64_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.8L10.3,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.7C9.6,19.2,9.9,18.8,10.3,18.8z"/>
          <path id="XMLID_63_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.9L10.3,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.4-0.7-0.8V19.7C9.6,19.2,9.9,18.9,10.3,18.9z"/>
          <path id="XMLID_62_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.9L10.3,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.3-0.7-0.8V19.7C9.6,19.2,9.9,18.9,10.3,18.9z"/>
          <path id="XMLID_61_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.9L10.3,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C9.6,19.2,10,18.9,10.3,18.9z"/>
          <path id="XMLID_60_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,18.9L10.3,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C9.7,19.3,10,18.9,10.3,18.9z"/>
          <path id="XMLID_59_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19L10.3,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C9.7,19.3,10,19,10.3,19z"/>
          <path id="XMLID_58_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19L10.3,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C9.7,19.3,10,19,10.3,19z"/>
          <path id="XMLID_57_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19L10.3,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C9.7,19.3,10,19,10.3,19z"/>
          <path id="XMLID_56_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19L10.3,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C9.7,19.3,10,19,10.3,19z"/>
          <path id="XMLID_55_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.1L10.3,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C9.8,19.3,10,19.1,10.3,19.1z"/>
          <path id="XMLID_54_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.1L10.3,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C9.8,19.4,10,19.1,10.3,19.1z"/>
          <path id="XMLID_53_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.1L10.3,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C9.8,19.4,10,19.1,10.3,19.1z"/>
          <path id="XMLID_52_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.2L10.3,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C9.8,19.4,10.1,19.2,10.3,19.2z"/>
          <path id="XMLID_51_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.2L10.3,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C9.9,19.4,10.1,19.2,10.3,19.2z"/>
          <path id="XMLID_50_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.2L10.3,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C9.9,19.4,10.1,19.2,10.3,19.2z"/>
          <path id="XMLID_49_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.2L10.3,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5h0c-0.2,0-0.4-0.2-0.4-0.5V19.7C9.9,19.4,10.1,19.2,10.3,19.2z"/>
          <path id="XMLID_48_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.3L10.3,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C9.9,19.5,10.1,19.3,10.3,19.3z"/>
          <path id="XMLID_47_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.3L10.3,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C9.9,19.5,10.1,19.3,10.3,19.3z"/>
          <path id="XMLID_45_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.3,19.3L10.3,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C10,19.5,10.1,19.3,10.3,19.3z"/>
        </g>
        <g id="XMLID_192_" opacity="0.5">
          <path id="XMLID_24_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.8L15.7,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C14.9,19.2,15.3,18.8,15.7,18.8z"/>
          <path id="XMLID_44_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.8L15.7,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9h0c-0.4,0-0.8-0.4-0.8-0.9V19.6C14.9,19.2,15.3,18.8,15.7,18.8z"/>
          <path id="XMLID_43_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.8L15.7,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.6C14.9,19.2,15.3,18.8,15.7,18.8z"/>
          <path id="XMLID_42_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.8L15.7,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.7C15,19.2,15.3,18.8,15.7,18.8z"/>
          <path id="XMLID_41_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.9L15.7,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.4-0.7-0.8V19.7C15,19.2,15.3,18.9,15.7,18.9z"/>
          <path id="XMLID_40_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.9L15.7,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.3-0.7-0.8V19.7C15,19.2,15.3,18.9,15.7,18.9z"/>
          <path id="XMLID_39_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.9L15.7,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C15,19.2,15.3,18.9,15.7,18.9z"/>
          <path id="XMLID_38_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,18.9L15.7,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C15.1,19.3,15.4,18.9,15.7,18.9z"/>
          <path id="XMLID_37_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19L15.7,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C15.1,19.3,15.4,19,15.7,19z"/>
          <path id="XMLID_36_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19L15.7,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C15.1,19.3,15.4,19,15.7,19z"/>
          <path id="XMLID_35_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19L15.7,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C15.1,19.3,15.4,19,15.7,19z"/>
          <path id="XMLID_34_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19L15.7,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C15.1,19.3,15.4,19,15.7,19z"/>
          <path id="XMLID_33_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.1L15.7,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C15.2,19.3,15.4,19.1,15.7,19.1z"/>
          <path id="XMLID_32_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.1L15.7,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C15.2,19.4,15.4,19.1,15.7,19.1z"/>
          <path id="XMLID_31_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.1L15.7,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C15.2,19.4,15.4,19.1,15.7,19.1z"/>
          <path id="XMLID_30_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.2L15.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C15.2,19.4,15.5,19.2,15.7,19.2z"/>
          <path id="XMLID_29_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.2L15.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C15.3,19.4,15.5,19.2,15.7,19.2z"/>
          <path id="XMLID_28_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.2L15.7,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C15.3,19.4,15.5,19.2,15.7,19.2z"/>
          <path id="XMLID_27_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.2L15.7,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5h0c-0.2,0-0.4-0.2-0.4-0.5V19.7C15.3,19.4,15.5,19.2,15.7,19.2z"/>
          <path id="XMLID_26_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.3L15.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C15.3,19.5,15.5,19.3,15.7,19.3z"/>
          <path id="XMLID_25_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.3L15.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C15.3,19.5,15.5,19.3,15.7,19.3z"/>
          <path id="XMLID_23_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M15.7,19.3L15.7,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C15.4,19.5,15.5,19.3,15.7,19.3z"/>
        </g>
        <g id="XMLID_165_" opacity="0.5">
          <path id="XMLID_2_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.8L18.5,18.8c0.5,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9l0,0c-0.5,0-0.8-0.4-0.8-0.9V19.6C17.7,19.2,18,18.8,18.5,18.8z"/>
          <path id="XMLID_22_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.8L18.5,18.8c0.4,0,0.8,0.4,0.8,0.9v27.8
            c0,0.5-0.4,0.9-0.8,0.9h0c-0.4,0-0.8-0.4-0.8-0.9V19.6C17.7,19.2,18,18.8,18.5,18.8z"/>
          <path id="XMLID_21_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.8L18.5,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.5-0.4,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.6C17.7,19.2,18.1,18.8,18.5,18.8z"/>
          <path id="XMLID_20_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.8L18.5,18.8c0.4,0,0.8,0.4,0.8,0.8v27.8
            c0,0.4-0.3,0.8-0.8,0.8h0c-0.4,0-0.8-0.4-0.8-0.8V19.7C17.7,19.2,18.1,18.8,18.5,18.8z"/>
          <path id="XMLID_19_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.9L18.5,18.9c0.4,0,0.7,0.4,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.4-0.7-0.8V19.7C17.7,19.2,18.1,18.9,18.5,18.9z"/>
          <path id="XMLID_18_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.9L18.5,18.9c0.4,0,0.7,0.3,0.7,0.8v27.8
            c0,0.4-0.3,0.8-0.7,0.8h0c-0.4,0-0.7-0.3-0.7-0.8V19.7C17.8,19.2,18.1,18.9,18.5,18.9z"/>
          <path id="XMLID_17_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.9L18.5,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C17.8,19.2,18.1,18.9,18.5,18.9z"/>
          <path id="XMLID_16_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,18.9L18.5,18.9c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C17.8,19.3,18.1,18.9,18.5,18.9z"/>
          <path id="XMLID_15_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19L18.5,19c0.4,0,0.7,0.3,0.7,0.7v27.8
            c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V19.7C17.8,19.3,18.1,19,18.5,19z"/>
          <path id="XMLID_14_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19L18.5,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C17.9,19.3,18.1,19,18.5,19z"/>
          <path id="XMLID_13_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19L18.5,19c0.3,0,0.6,0.3,0.6,0.7v27.8
            c0,0.4-0.3,0.7-0.6,0.7h0c-0.3,0-0.6-0.3-0.6-0.7V19.7C17.9,19.3,18.1,19,18.5,19z"/>
          <path id="XMLID_12_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19L18.5,19c0.3,0,0.6,0.3,0.6,0.6v27.8
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C17.9,19.3,18.2,19,18.5,19z"/>
          <path id="XMLID_11_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.1L18.5,19.1c0.3,0,0.6,0.3,0.6,0.6v27.7
            c0,0.3-0.3,0.6-0.6,0.6h0c-0.3,0-0.6-0.3-0.6-0.6V19.7C17.9,19.3,18.2,19.1,18.5,19.1z"/>
          <path id="XMLID_10_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.1L18.5,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C17.9,19.4,18.2,19.1,18.5,19.1z"/>
          <path id="XMLID_9_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.1L18.5,19.1c0.3,0,0.5,0.3,0.5,0.6v27.7
            c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.3-0.5-0.6V19.7C18,19.4,18.2,19.1,18.5,19.1z"/>
          <path id="XMLID_8_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.2L18.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C18,19.4,18.2,19.2,18.5,19.2z"/>
          <path id="XMLID_7_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.2L18.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C18,19.4,18.2,19.2,18.5,19.2z"/>
          <path id="XMLID_6_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.2L18.5,19.2c0.3,0,0.5,0.2,0.5,0.5v27.7
            c0,0.3-0.2,0.5-0.5,0.5h0c-0.3,0-0.5-0.2-0.5-0.5V19.7C18,19.4,18.2,19.2,18.5,19.2z"/>
          <path id="XMLID_5_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.2L18.5,19.2c0.2,0,0.4,0.2,0.4,0.5v27.7
            c0,0.3-0.2,0.5-0.4,0.5h0c-0.2,0-0.4-0.2-0.4-0.5V19.7C18.1,19.4,18.2,19.2,18.5,19.2z"/>
          <path id="XMLID_4_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.3L18.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C18.1,19.5,18.3,19.3,18.5,19.3z"/>
          <path id="XMLID_3_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.3L18.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C18.1,19.5,18.3,19.3,18.5,19.3z"/>
          <path id="XMLID_1_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M18.5,19.3L18.5,19.3c0.2,0,0.4,0.2,0.4,0.4v27.7
            c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V19.7C18.1,19.5,18.3,19.3,18.5,19.3z"/>
        </g>
        <g id="XMLID_161_">
          <linearGradient id="XMLID_216_" gradientUnits="userSpaceOnUse" x1="11.603" y1="1.393" x2="11.603" y2="-0.5894">
            <stop  offset="0" style="stop-color:#E6E7E8"/>
            <stop  offset="1" style="stop-color:#E6E7E8"/>
          </linearGradient>
          <path id="XMLID_162_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_216_)" d="M7.3,0.6C7.1,0.7,6.8,1,6.6,1.1
            C5.7,1.6,5.2,1.9,4.8,2.3C5.2,2,5.7,1.7,6.5,1.3c0.2-0.1,0.5-0.5,0.7-0.6c0.9-0.3,2.7-0.5,4.4-0.6c1.8,0,3.5,0.2,4.4,0.6
            c0.3,0.1,0.5,0.5,0.7,0.6C17.5,1.7,18,2,18.4,2.3c-0.4-0.4-1-0.7-1.8-1.1c-0.2-0.1-0.4-0.5-0.7-0.6C15,0.2,13.3,0,11.6,0
            C9.9,0,8.2,0.2,7.3,0.6L7.3,0.6z M11.5,50c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0H11.5z"/>
        </g>
        <path id="XMLID_158_" fill-rule="evenodd" clip-rule="evenodd" d="M20.5,17.1h0.2c0.1,0,0.1,0,0.2-0.1l0,0c0,0,0.1-0.1,0.1-0.2
          v-1.3c0,0,0-0.1,0-0.1h0c0,0,0-0.1-0.1-0.1l-0.3-0.2l0,0.3l0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0
          h-0.2L20.5,17.1L20.5,17.1z M2.7,17.1H2.5c-0.1,0-0.1,0-0.2-0.1l0,0c0,0-0.1-0.1-0.1-0.2v-1.3c0,0,0-0.1,0-0.1h0c0,0,0-0.1,0.1-0.1
          l0.3-0.2l0,0.3l-0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0h0.2L2.7,17.1z"/>
        <path id="XMLID_157_" fill-rule="evenodd" clip-rule="evenodd" fill="#EE3F25" d="M8.7,49.9C9.6,50,10.6,50,11.6,50
          c1,0,2,0,2.9-0.1v-0.2c0-0.4-0.2-0.6-0.4-0.6h-5c-0.2,0-0.4,0.3-0.4,0.6V49.9z"/>
      </g>
      </svg>
      `)
  }

  static vehicleMarkerSedan = (bodyColor: string) => {
    const showOutline = bodyColor === 'white'

    return SVG.dataUrl(`<?xml version="1.0" encoding="utf-8"?>
      <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) -->
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        ${!showOutline ? 'viewBox="0 0 22.7 50" enable-background="new 0 0 22.7 50"' : ''}
        ${
          /* If there is an outline, increase the viewBox size and shift the graphic to center it */ ''
        }
        ${showOutline ? 'viewBox="-0.15 -1 23 52" enable-background="new -0.15 -1 23 52"' : ''}
        xml:space="preserve">
      <g id="XMLID_25_">
        <path id="XMLID_163_" fill-rule="evenodd" clip-rule="evenodd" d="M2.1,26.9H1.8c-0.1,0-0.1,0-0.2-0.1l0,0c0-0.1-0.1-0.1-0.1-0.2
          v-1.5c0-0.1,0-0.1,0-0.1h0c0,0,0-0.1,0.1-0.1L2,24.7L2,25l-0.2,0.2c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.2c0,0,0,0,0,0.1c0,0,0,0,0.1,0
          h0.2L2.1,26.9z"/>
        <path id="XMLID_162_" fill-rule="evenodd" clip-rule="evenodd" d="M20.7,26.9H21c0.1,0,0.1,0,0.2-0.1l0,0c0-0.1,0.1-0.1,0.1-0.2
          v-1.5c0-0.1,0-0.1,0-0.1h0c0,0,0-0.1-0.1-0.1l-0.3-0.3l0,0.4l0.2,0.2c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0
          h-0.2L20.7,26.9z"/>
        <path id="XMLID_161_" fill-rule="evenodd" clip-rule="evenodd" d="M2.2,36.8H2c-0.1,0-0.1,0-0.2-0.1l0,0c0-0.1-0.1-0.1-0.1-0.2
          v-1.5c0-0.1,0-0.1,0-0.1h0c0,0,0-0.1,0.1-0.1l0.3-0.2l0,0.4L2,35.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.2c0,0,0,0,0,0.1c0,0,0,0,0.1,0
          h0.2L2.2,36.8z"/>
        <path id="XMLID_160_" fill-rule="evenodd" clip-rule="evenodd" d="M20.5,36.8h0.2c0.1,0,0.1,0,0.2-0.1l0,0c0-0.1,0.1-0.1,0.1-0.2
          v-1.5c0-0.1,0-0.1,0-0.1h0c0,0,0-0.1-0.1-0.1l-0.3-0.2l0,0.4l0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0v1.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0
          h-0.2L20.5,36.8z"/>
        <path id="XMLID_159_" fill-rule="evenodd" clip-rule="evenodd" fill="#EE3F25" d="M3.1,45.7c0,0,0.3,2.4,0.5,2.6
          c0.2,0.2,1.8,1,2.9,1V48c-0.3-0.4-0.6-0.8-0.6-0.8L3.1,45.7z"/>
        <path id="XMLID_158_" fill-rule="evenodd" clip-rule="evenodd" fill="#EE4024" d="M19.7,45.7c0,0-0.3,2.4-0.5,2.6
          c-0.2,0.2-1.8,1-2.9,1V48c0.3-0.4,0.6-0.8,0.6-0.8L19.7,45.7z"/>
        <g id="XMLID_54_" ${showOutline ? 'stroke="black" stroke-width="2.5"' : ''}>
          <path id="XMLID_3_" fill-rule="evenodd" clip-rule="evenodd" fill="#A6A8AB" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.8
            C6.6,0.9,6.4,1.2,5.3,1.4C4.1,1.6,3,2.5,2.6,3.8S1.4,8.2,1.7,9.9c0.3,1.7,0.3,3.5,0.2,4.7c-0.1,1.2,0.2,20.5,0.3,21.3
            c0,0.8-0.2,4.5-0.1,5.4c0.1,0.8,0.6,1.8,0.8,3.4c0.2,1.6,0.2,2.6,1.5,3.6c1.3,1,4.9,1.4,5.8,1.5c0.9,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.9-0.1,4.5-0.5,5.8-1.5c1.3-1,1.3-2,1.5-3.6c0.2-1.6,0.7-2.6,0.8-3.4c0.1-0.8-0.1-4.5-0.1-5.4
            c0-0.8,0.4-20.1,0.3-21.3s-0.1-3,0.2-4.7c0.3-1.7-0.5-4.9-0.9-6.2s-1.6-2.1-2.7-2.3c-1.1-0.2-1.3-0.5-1.7-0.6
            C14.9,0.5,12.5,0.4,11.4,0.2z"/>
          <path id="XMLID_22_" fill-rule="evenodd" clip-rule="evenodd" fill="#A7A9AC" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.8
            C6.6,1,6.4,1.2,5.3,1.4C4.1,1.6,3,2.5,2.6,3.8C2.2,5.1,1.5,8.2,1.7,9.9C2,11.7,2,13.6,1.9,14.8C1.8,16,2.1,35.1,2.2,35.9
            c0,0.8-0.2,4.5-0.1,5.3c0.1,0.8,0.6,1.9,0.8,3.5c0.2,1.6,0.2,2.6,1.5,3.6c1.3,1,4.9,1.4,5.8,1.5c0.9,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.9-0.1,4.5-0.5,5.8-1.5c1.3-1,1.3-2,1.5-3.6c0.2-1.6,0.7-2.7,0.8-3.5c0.1-0.8-0.1-4.4-0.1-5.3
            c0-0.8,0.4-20,0.3-21.2c-0.1-1.2-0.1-3.1,0.2-4.8c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.5-2.1-2.7-2.3S16.2,1,15.8,0.8
            C14.9,0.5,12.5,0.4,11.4,0.2z"/>
          <path id="XMLID_21_" fill-rule="evenodd" clip-rule="evenodd" fill="#A8AAAD" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.8
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3,2.5,2.6,3.8C2.2,5.1,1.5,8.2,1.8,9.9c0.3,1.7,0.2,3.7,0.1,5c-0.1,1.2,0.3,20.2,0.3,21
            c0,0.8-0.2,4.4-0.1,5.2c0.1,0.8,0.6,2,0.8,3.6c0.2,1.6,0.2,2.6,1.5,3.6c1.3,1,4.9,1.4,5.7,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.5-0.5,5.7-1.5c1.3-1,1.3-2,1.5-3.6c0.2-1.6,0.7-2.8,0.8-3.6c0.1-0.8-0.1-4.4-0.1-5.2
            c0-0.8,0.4-19.8,0.3-21.1c-0.1-1.2-0.1-3.2,0.1-5c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.5-2.1-2.7-2.3S16.2,1,15.8,0.8
            C14.9,0.5,12.5,0.4,11.4,0.2z"/>
          <path id="XMLID_20_" fill-rule="evenodd" clip-rule="evenodd" fill="#A9ABAE" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.8
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.1,2.5,2.7,3.8C2.3,5.1,1.6,8.2,1.8,9.9C2.1,11.7,2,13.8,1.9,15c-0.1,1.2,0.3,20.1,0.3,20.9
            c0,0.8-0.2,4.3-0.1,5.1c0.1,0.8,0.6,2.1,0.8,3.7c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.9,1.4,5.7,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.5-0.5,5.7-1.5c1.2-1,1.3-2,1.5-3.6c0.2-1.6,0.7-2.9,0.8-3.7c0.1-0.8-0.1-4.3-0.1-5.1
            c0-0.8,0.4-19.7,0.3-20.9c-0.1-1.2-0.1-3.3,0.1-5.1c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.5-2.1-2.6-2.3c-1.1-0.2-1.3-0.4-1.7-0.6
            C14.9,0.5,12.5,0.4,11.4,0.2z"/>
          <path id="XMLID_19_" fill-rule="evenodd" clip-rule="evenodd" fill="#AAACAF" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.9
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.1,2.5,2.7,3.8C2.3,5.1,1.6,8.2,1.9,9.9c0.3,1.7,0.2,4,0.1,5.2c-0.1,1.2,0.3,20,0.3,20.8
            c0,0.8-0.2,4.2-0.1,5c0.1,0.8,0.6,2.2,0.8,3.8c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.8,1.4,5.7,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.4-0.5,5.7-1.5c1.2-1,1.3-2,1.5-3.6s0.7-3,0.8-3.8c0.1-0.8-0.1-4.2-0.1-5c0-0.8,0.4-19.6,0.3-20.8
            c-0.1-1.2-0.1-3.5,0.1-5.2c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.5-2.1-2.6-2.3c-1.1-0.2-1.3-0.4-1.7-0.6
            C14.9,0.5,12.5,0.4,11.4,0.2z"/>
          <path id="XMLID_18_" fill-rule="evenodd" clip-rule="evenodd" fill="#ABADB0" d="M11.4,0.2C10.3,0.4,7.9,0.5,7,0.9
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.2,2.5,2.8,3.8C2.4,5.1,1.7,8.2,1.9,9.9c0.3,1.7,0.2,4.1,0.1,5.4c-0.1,1.2,0.3,19.8,0.4,20.6
            c0,0.8-0.2,4.1-0.1,4.9c0.1,0.8,0.6,2.3,0.8,3.9c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.8,1.4,5.6,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.4-0.5,5.6-1.5c1.2-1,1.3-2,1.5-3.6c0.2-1.6,0.7-3.1,0.8-3.9c0.1-0.8-0.1-4.1-0.1-4.9
            c0-0.8,0.4-19.5,0.3-20.7c-0.1-1.2-0.2-3.6,0.1-5.3c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.4-2.1-2.6-2.3S16.2,1,15.8,0.9
            C14.9,0.5,12.4,0.4,11.4,0.2z"/>
          <path id="XMLID_17_" fill-rule="evenodd" clip-rule="evenodd" fill="#ACAEB1" d="M11.4,0.3C10.3,0.4,7.9,0.6,7,0.9
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.2,2.5,2.8,3.8C2.4,5.1,1.7,8.2,1.9,9.9c0.3,1.7,0.2,4.3,0.1,5.5c-0.1,1.2,0.3,19.7,0.4,20.5
            c0,0.8-0.2,4-0.1,4.8c0.1,0.8,0.6,2.4,0.8,4c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.8,1.4,5.6,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.4-0.5,5.6-1.5c1.2-1,1.2-2,1.5-3.6c0.2-1.6,0.7-3.2,0.8-4c0.1-0.8-0.1-4-0.1-4.8
            c0-0.8,0.5-19.3,0.4-20.6c-0.1-1.2-0.2-3.7,0.1-5.4c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.4-2.1-2.5-2.3S16.2,1,15.8,0.9
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_16_" fill-rule="evenodd" clip-rule="evenodd" fill="#ADAFB2" d="M11.4,0.3C10.3,0.4,7.9,0.6,7,0.9
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.2,2.5,2.8,3.8C2.5,5.1,1.7,8.2,2,9.9c0.3,1.7,0.1,4.4,0,5.6c-0.1,1.2,0.4,19.6,0.4,20.4
            c0,0.8-0.2,3.9-0.1,4.7c0.1,0.8,0.6,2.5,0.8,4.1c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.8,1.4,5.6,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.4-0.5,5.6-1.5c1.2-1,1.2-2,1.5-3.6c0.2-1.6,0.7-3.3,0.8-4.1c0.1-0.8-0.1-3.9-0.1-4.7
            c0-0.8,0.5-19.2,0.4-20.4c-0.1-1.2-0.2-3.8,0-5.6c0.3-1.7-0.5-4.9-0.9-6.2c-0.4-1.3-1.4-2.1-2.5-2.3S16.2,1,15.8,0.9
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_15_" fill-rule="evenodd" clip-rule="evenodd" fill="#AEB0B3" d="M11.4,0.3C10.3,0.4,7.9,0.6,7,0.9
            C6.6,1,6.4,1.2,5.3,1.4C4.2,1.6,3.3,2.5,2.9,3.8C2.5,5.1,1.8,8.2,2,9.9c0.3,1.7,0.1,4.5,0,5.8C2,16.9,2.4,35.1,2.5,35.9
            c0,0.8-0.2,3.8-0.1,4.6c0.1,0.8,0.6,2.6,0.8,4.2c0.2,1.6,0.2,2.6,1.5,3.6c1.2,1,4.7,1.4,5.6,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.3-0.5,5.6-1.5c1.2-1,1.2-2,1.5-3.6c0.2-1.6,0.7-3.4,0.8-4.2c0.1-0.8-0.1-3.8-0.1-4.6
            c0-0.8,0.5-19.1,0.4-20.3c-0.1-1.2-0.2-3.9,0-5.7c0.3-1.7-0.5-4.9-0.8-6.2c-0.4-1.3-1.4-2.1-2.5-2.3c-1.1-0.2-1.3-0.4-1.6-0.5
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_14_" fill-rule="evenodd" clip-rule="evenodd" fill="#AFB1B4" d="M11.4,0.3c-1,0.2-3.5,0.3-4.4,0.6
            C6.6,1.1,6.5,1.2,5.4,1.4c-1.1,0.2-2,1.1-2.4,2.3C2.5,5.1,1.8,8.2,2.1,9.9c0.2,1.7,0.1,4.7,0,5.9C2,17,2.5,35.1,2.5,35.9
            c0,0.8-0.2,3.7-0.1,4.5c0.1,0.8,0.6,2.7,0.8,4.3c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.7,1.4,5.5,1.5c0.8,0.1,1,0.1,1.2,0.1
            c0.2,0,0.3,0,1.2-0.1c0.8-0.1,4.3-0.5,5.5-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.7-3.5,0.8-4.3c0.1-0.8-0.1-3.7-0.1-4.5
            c0-0.8,0.5-19,0.4-20.2c-0.1-1.2-0.2-4.1,0-5.8c0.2-1.7-0.5-4.9-0.8-6.2c-0.4-1.3-1.3-2.1-2.4-2.3c-1.1-0.2-1.3-0.4-1.6-0.5
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_13_" fill-rule="evenodd" clip-rule="evenodd" fill="#B0B2B5" d="M11.4,0.3c-1,0.2-3.5,0.3-4.4,0.6
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.4,2.5,3,3.8C2.6,5.1,1.9,8.2,2.1,9.9c0.2,1.7,0.1,4.8,0,6c-0.1,1.2,0.4,19.2,0.4,20
            c0,0.8-0.2,3.6-0.1,4.4c0.1,0.8,0.6,2.8,0.8,4.4c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.7,1.4,5.5,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.3-0.5,5.5-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.7-3.6,0.8-4.4c0.1-0.8-0.1-3.6-0.1-4.4
            c0-0.8,0.5-18.9,0.4-20.1c-0.1-1.2-0.3-4.2,0-5.9c0.2-1.7-0.5-4.9-0.8-6.2c-0.4-1.3-1.3-2.1-2.4-2.3s-1.2-0.4-1.6-0.5
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_12_" fill-rule="evenodd" clip-rule="evenodd" fill="#B1B3B5" d="M11.4,0.3c-1,0.2-3.5,0.3-4.4,0.7
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.4,2.5,3,3.8C2.6,5.1,1.9,8.2,2.2,9.9c0.2,1.7,0.1,4.9,0,6.1C2,17.3,2.6,35.1,2.6,35.9
            c0,0.8-0.2,3.5-0.1,4.3c0.1,0.8,0.6,2.9,0.8,4.5c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.7,1.4,5.5,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.3-0.5,5.5-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.7-3.7,0.8-4.5c0.1-0.8-0.1-3.5-0.1-4.3
            c0-0.8,0.5-18.7,0.5-20c-0.1-1.2-0.3-4.3,0-6c0.2-1.7-0.5-4.9-0.8-6.2c-0.4-1.3-1.3-2.1-2.4-2.3s-1.2-0.3-1.6-0.5
            C14.9,0.6,12.4,0.4,11.4,0.3z"/>
          <path id="XMLID_11_" fill-rule="evenodd" clip-rule="evenodd" fill="#B2B4B6" d="M11.4,0.3C10.3,0.5,7.8,0.6,7,1
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.4,2.5,3,3.8C2.7,5.1,2,8.2,2.2,9.9c0.2,1.7,0,5.1-0.1,6.3c-0.1,1.2,0.5,18.9,0.5,19.7
            c0,0.8-0.2,3.4-0.1,4.2c0.1,0.8,0.6,3,0.8,4.6c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.7,1.4,5.5,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.3-0.5,5.5-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.7-3.8,0.8-4.6c0.1-0.8-0.1-3.4-0.1-4.2
            c0-0.8,0.6-18.6,0.5-19.8c-0.1-1.2-0.3-4.4-0.1-6.2c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.2-2.1-2.3-2.3c-1.1-0.2-1.2-0.3-1.6-0.5
            C14.9,0.6,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_10_" fill-rule="evenodd" clip-rule="evenodd" fill="#B3B5B7" d="M11.4,0.3C10.4,0.5,7.8,0.6,7,1
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.5,2.5,3.1,3.8C2.7,5.1,2,8.2,2.3,9.9c0.2,1.7,0,5.2-0.1,6.4c-0.1,1.2,0.5,18.8,0.5,19.6
            c0,0.8-0.2,3.3-0.1,4.1c0.1,0.8,0.6,3.1,0.8,4.7c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.6,1.4,5.4,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.2-0.5,5.4-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-3.9,0.8-4.7c0.1-0.8-0.1-3.3-0.1-4.1
            c0-0.8,0.6-18.5,0.5-19.7c-0.1-1.2-0.3-4.6-0.1-6.3c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.2-2.1-2.3-2.3c-1.1-0.2-1.2-0.3-1.6-0.5
            C14.9,0.6,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_9_" fill-rule="evenodd" clip-rule="evenodd" fill="#B4B6B8" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.5,2.5,3.1,3.8C2.7,5.1,2.1,8.2,2.3,9.9c0.2,1.7,0,5.3-0.1,6.5c-0.1,1.2,0.5,18.7,0.5,19.5
            c0,0.8-0.2,3.2-0.1,4c0.1,0.8,0.6,3.1,0.8,4.8c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.6,1.4,5.4,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.2-0.5,5.4-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4,0.8-4.8c0.1-0.8-0.1-3.2-0.1-4
            c0-0.8,0.6-18.4,0.5-19.6c-0.1-1.2-0.3-4.7-0.1-6.4c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.2-2.1-2.3-2.3S16.1,1.1,15.8,1
            C14.9,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_8_" fill-rule="evenodd" clip-rule="evenodd" fill="#B5B7B9" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.5,2.5,3.2,3.8C2.8,5.1,2.1,8.2,2.3,9.9c0.2,1.7,0,5.4-0.1,6.7c-0.1,1.2,0.5,18.5,0.5,19.3
            c0,0.8-0.2,3.1-0.1,3.9c0.1,0.8,0.6,3.2,0.8,4.9c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.6,1.4,5.4,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.2-0.5,5.4-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4,0.8-4.9c0.1-0.8-0.1-3.1-0.1-3.9
            c0-0.8,0.6-18.2,0.5-19.5c-0.1-1.2-0.4-4.8-0.1-6.5c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.2-2.1-2.2-2.3S16.1,1.1,15.8,1
            C14.9,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_7_" fill-rule="evenodd" clip-rule="evenodd" fill="#B6B8BA" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.1,6.5,1.2,5.4,1.4C4.3,1.6,3.6,2.5,3.2,3.8C2.8,5.1,2.2,8.2,2.4,9.9c0.2,1.7-0.1,5.6-0.1,6.8C2.2,18,2.8,35.1,2.8,35.9
            c0,0.8-0.2,3-0.1,3.8c0.1,0.8,0.5,3.3,0.8,4.9c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.6,1.4,5.4,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.2-0.5,5.4-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.1,0.8-4.9c0.1-0.8-0.1-3-0.1-3.8
            c0-0.8,0.6-18.1,0.5-19.3c-0.1-1.2-0.4-4.9-0.1-6.7c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.1-2.1-2.2-2.3c-1.1-0.2-1.2-0.3-1.6-0.4
            C14.9,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_6_" fill-rule="evenodd" clip-rule="evenodd" fill="#B7B9BB" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.2,6.5,1.2,5.4,1.4C4.4,1.6,3.6,2.5,3.2,3.8C2.9,5.1,2.2,8.2,2.4,9.9c0.2,1.7-0.1,5.7-0.2,6.9c-0.1,1.2,0.5,18.3,0.6,19.1
            c0,0.8-0.2,2.9-0.1,3.7c0.1,0.8,0.5,3.4,0.8,5c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.5,1.4,5.3,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.2-0.5,5.3-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.2,0.8-5c0.1-0.8-0.1-2.9-0.1-3.7
            c0-0.8,0.6-18,0.6-19.2c-0.1-1.2-0.4-5-0.1-6.8c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.1-2.1-2.2-2.3c-1.1-0.2-1.2-0.3-1.6-0.4
            C14.9,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_5_" fill-rule="evenodd" clip-rule="evenodd" fill="#B8BABC" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.2,6.5,1.2,5.4,1.4C4.4,1.6,3.7,2.5,3.3,3.8c-0.4,1.3-1,4.4-0.8,6.2c0.2,1.7-0.1,5.8-0.2,7.1c-0.1,1.2,0.6,18.1,0.6,18.9
            c0,0.8-0.2,2.8-0.1,3.6c0.1,0.8,0.5,3.5,0.8,5.1c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.5,1.4,5.3,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.1-0.5,5.3-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.3,0.8-5.1c0.1-0.8-0.1-2.8-0.1-3.6
            c0-0.8,0.7-17.9,0.6-19.1c-0.1-1.2-0.4-5.2-0.2-6.9c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.1-2.1-2.1-2.3c-1.1-0.2-1.2-0.3-1.6-0.4
            C14.9,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_4_" fill-rule="evenodd" clip-rule="evenodd" fill="#B9BBBD" d="M11.4,0.3C10.4,0.5,7.8,0.7,7,1
            C6.6,1.2,6.5,1.2,5.4,1.4c-1,0.2-1.7,1.1-2.1,2.3C3,5.1,2.3,8.2,2.5,9.9c0.2,1.7-0.1,6-0.2,7.2c-0.1,1.2,0.6,18,0.6,18.8
            c0,0.8-0.2,2.7-0.1,3.5c0.1,0.8,0.5,3.6,0.7,5.2c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.5,1.4,5.3,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.1-0.5,5.3-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.4,0.7-5.2c0.1-0.8-0.1-2.7-0.1-3.5
            c0-0.8,0.7-17.8,0.6-19c-0.1-1.2-0.4-5.3-0.2-7c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1.1-2.1-2.1-2.3S16.1,1.2,15.8,1
            C15,0.7,12.4,0.5,11.4,0.3z"/>
          <path id="XMLID_23_" fill-rule="evenodd" clip-rule="evenodd" fill="#BABCBE" d="M11.4,0.4c-1,0.2-3.6,0.4-4.4,0.7
            C6.6,1.2,6.5,1.2,5.4,1.4c-1,0.2-1.7,1.1-2.1,2.3C3,5.1,2.3,8.2,2.6,9.9c0.2,1.7-0.1,6.1-0.2,7.3C2.3,18.5,3,35.1,3,35.9
            c0,0.8-0.2,2.6-0.1,3.4c0.1,0.8,0.5,3.7,0.7,5.3c0.2,1.6,0.2,2.6,1.4,3.6c1.2,1,4.5,1.4,5.3,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.1-0.5,5.3-1.5c1.2-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.5,0.7-5.3c0.1-0.8-0.1-2.6-0.1-3.4
            c0-0.8,0.7-17.6,0.6-18.9c-0.1-1.2-0.4-5.4-0.2-7.1c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1-2.1-2.1-2.3s-1.2-0.2-1.5-0.4
            C15,0.7,12.4,0.5,11.4,0.4z"/>
          <path id="XMLID_24_" fill-rule="evenodd" clip-rule="evenodd" fill="#BBBDBF" d="M11.4,0.4c-1,0.2-3.6,0.4-4.4,0.7
            C6.6,1.2,6.5,1.2,5.4,1.4c-1,0.2-1.7,1.1-2,2.3C3,5.1,2.4,8.2,2.6,9.9s-0.2,6.2-0.2,7.4C2.3,18.6,3,35.1,3,35.9
            c0,0.8-0.2,2.5-0.1,3.3c0.1,0.8,0.5,3.8,0.7,5.4c0.2,1.6,0.2,2.6,1.4,3.6c1.1,1,4.5,1.4,5.2,1.5c0.8,0.1,0.9,0.1,1.1,0.1
            c0.2,0,0.3,0,1.1-0.1c0.8-0.1,4.1-0.5,5.2-1.5c1.1-1,1.2-2,1.4-3.6c0.2-1.6,0.6-4.6,0.7-5.4c0.1-0.8-0.1-2.5-0.1-3.3
            c0-0.8,0.7-17.5,0.6-18.7c-0.1-1.2-0.5-5.5-0.2-7.3c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1-2.1-2-2.3c-1-0.2-1.2-0.2-1.5-0.4
            C15,0.7,12.4,0.5,11.4,0.4z"/>
        </g>
        <path id="SVG_x5F_BODY_x5F_COLOR" fill-rule="evenodd" clip-rule="evenodd" fill="${bodyColor}" d="M6.9,0.8C6.6,0.9,6.5,1.2,5.4,1.4
          c-1,0.2-1.7,1.1-2,2.3C3,5.1,2.4,8.2,2.6,9.9s-0.2,5.2-0.2,6.4c-0.1,1.2,0.2,19.2,0.3,20c0.1,3.9,0.2,4.5,0.7,8.4
          c0.2,1.6,0.6,2.7,1.8,3.8c1.1,1,4.5,1.4,5.2,1.5c0.7,0.1,1.5,0.1,2.2,0c0.8-0.1,4.1-0.4,5.2-1.5c1.1-1,1.5-2.1,1.8-3.8
          c0.5-3.8,0.5-4.4,0.7-8.4c0-0.8,0.3-18.9,0.3-20.1c-0.1-1.2-0.5-4.5-0.2-6.3c0.2-1.7-0.4-4.9-0.8-6.2c-0.4-1.3-1-2.1-2-2.3
          c-1-0.2-1.1-0.5-1.5-0.6C13.6-0.1,9.2-0.1,6.9,0.8z"/>
        <path id="XMLID_52_" fill-rule="evenodd" clip-rule="evenodd" d="M2.4,17.4c0,0,0.2,9.3,0.2,10.3c0,1,0.3,10.1,0.3,10.8
          c0,0.7,0.5,1.9,0.8,1.2c0.2-0.4,0.4-2.5,0.5-4.9c0.1-2.5,0.1-5.2,0-6.5C4.1,24.8,3,21,2.4,17.4z"/>
        <path id="XMLID_51_" fill-rule="evenodd" clip-rule="evenodd" d="M20.4,17.4c0,0-0.2,9.3-0.2,10.3c0,1-0.3,10.1-0.3,10.8
          c0,0.7-0.5,1.9-0.8,1.2c-0.2-0.4-0.4-2.5-0.5-4.9c-0.1-2.5-0.1-5.2,0-6.5C18.6,24.8,19.8,21,20.4,17.4z"/>
        <path id="XMLID_47_" fill-rule="evenodd" clip-rule="evenodd" fill="#0B383E" d="M3.7,39.6c0.1-0.4,0.3-1.7,0.4-3.4
          c-0.4-0.2-0.7-0.4-0.9-0.6c0,1.5,0.1,2.7,0.1,3C3.3,39.2,3.5,40,3.7,39.6L3.7,39.6z M4.1,35.8c-0.4-0.2-0.7-0.4-1-0.6
          C3.1,33.3,3,31.1,3,29.5c0.5,0.4,0.9,0.6,1.2,0.7c0,1.3,0,2.9-0.1,4.5C4.2,35.1,4.1,35.4,4.1,35.8L4.1,35.8z M4.2,29.6
          c0-0.6,0-1.1,0-1.4c-0.1-2.7-0.8-5.8-1.4-8.8c0.1,2.8,0.2,7.6,0.2,8.3c0,0.1,0,0.2,0,0.5C3.2,28.7,3.7,29.3,4.2,29.6z"/>
        <path id="XMLID_43_" fill-rule="evenodd" clip-rule="evenodd" fill="#0B383E" d="M19,39.6c-0.1-0.4-0.3-1.7-0.4-3.4
          c0.4-0.2,0.7-0.4,0.9-0.6c0,1.5-0.1,2.7-0.1,3C19.5,39.2,19.3,40,19,39.6L19,39.6z M18.6,35.8c0.4-0.2,0.7-0.4,1-0.6
          c0.1-1.9,0.1-4.1,0.2-5.6c-0.5,0.4-0.9,0.6-1.2,0.7c0,1.3,0,2.9,0.1,4.5C18.6,35.1,18.6,35.4,18.6,35.8L18.6,35.8z M18.5,29.6
          c0-0.6,0-1.1,0-1.4c0.1-2.7,0.8-5.8,1.4-8.8c-0.1,2.8-0.2,7.6-0.2,8.3c0,0.1,0,0.2,0,0.5C19.5,28.7,19.1,29.3,18.5,29.6z"/>
        <linearGradient id="XMLID_2_" gradientUnits="userSpaceOnUse" x1="1.1153" y1="18.6225" x2="2.0773" y2="20.3579">
          <stop  offset="0" style="stop-color:#34383A"/>
          <stop  offset="1" style="stop-color:#131618"/>
        </linearGradient>
        <path id="XMLID_42_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_2_)" d="M2.4,17.4c0,0,0,0.7-0.3,0.8
          C1.2,18.6,0.2,19,0,20c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0L2,19.7l0.1-0.4l0.3-0.1L2.4,17.4z"/>
        <path id="XMLID_41_" fill-rule="evenodd" clip-rule="evenodd" fill="#131618" d="M2.5,18.5c0,0-1.6,0.6-2,1C0.3,19.6,0.1,19.8,0,20
          c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1L2,19.7l0.1-0.4l0.3-0.1L2.5,18.5z"/>
        <linearGradient id="XMLID_53_" gradientUnits="userSpaceOnUse" x1="21.6396" y1="18.4387" x2="20.6475" y2="20.1571">
          <stop  offset="0" style="stop-color:#34383A"/>
          <stop  offset="1" style="stop-color:#131618"/>
        </linearGradient>
        <path id="XMLID_40_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_53_)" d="M20.4,17.2c0,0,0,0.7,0.3,0.8
          c0.9,0.4,1.9,0.8,2.1,1.8c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0l-1.8-0.5l-0.1-0.4L20.3,19L20.4,17.2z"/>
        <path id="XMLID_39_" fill-rule="evenodd" clip-rule="evenodd" fill="#131618" d="M20.3,18.3c0,0,1.6,0.6,2,1
          c0.2,0.1,0.4,0.4,0.4,0.5c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.1l-1.8-0.5l-0.1-0.4L20.3,19L20.3,18.3z"/>
        <path id="XMLID_38_" fill-rule="evenodd" clip-rule="evenodd" d="M3.2,16.2l1.2,7.3c3.9-1,10.2-1,14,0l1.2-7.3
          C16.5,13.7,6.2,13.7,3.2,16.2z"/>
        <path id="XMLID_37_" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20" d="M6.9,0.8C6.8,0.8,6.7,0.9,6.6,1c2.4-1,7-1,9.5,0
          c-0.1-0.1-0.2-0.1-0.3-0.2C13.6-0.3,9.2-0.3,6.9,0.8z"/>
        <linearGradient id="XMLID_58_" gradientUnits="userSpaceOnUse" x1="0.7359" y1="3.3016" x2="2.236" y2="3.3278">
          <stop  offset="0" style="stop-color:#929497"/>
          <stop  offset="1" style="stop-color:#E6E7E8"/>
        </linearGradient>
        <path id="XMLID_36_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_58_)" d="M6.2,1.2C5.9,1.3,5.7,1.4,5.3,1.4
          C4.1,1.6,3,2.5,2.6,3.8c-0.1,0.4-0.3,1-0.5,1.7c0.1,0,0.3,0,0.4-0.2c0.6-0.8,1.6-2.5,2.9-3.4C5.8,1.7,5.9,1.4,6.2,1.2z"/>
        <path id="XMLID_35_" fill-rule="evenodd" clip-rule="evenodd" fill="#B9BABC" d="M6.2,1.2C5.4,1.5,4.5,1.6,4,2.1
          c-0.6,0.5-1,1.3-1.2,1.8C2.5,4.8,2.3,4.8,2.1,5.5c0.1,0,0.3,0,0.4-0.2c0.6-0.8,1.6-2.5,2.9-3.4C5.8,1.7,5.9,1.4,6.2,1.2z"/>
        <path id="XMLID_34_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M3.2,4.5c0.6-0.9,1.4-2,2.3-2.6
          c0.2-0.2,0.1-0.1,0.3-0.3C5.6,1.7,5.5,1.7,5.3,1.8C3.4,2.4,3.6,2.5,3.2,4.5z"/>
        <linearGradient id="XMLID_59_" gradientUnits="userSpaceOnUse" x1="22.0117" y1="3.3617" x2="20.5113" y2="3.3617">
          <stop  offset="0" style="stop-color:#929497"/>
          <stop  offset="1" style="stop-color:#E6E7E8"/>
        </linearGradient>
        <path id="XMLID_33_" fill-rule="evenodd" clip-rule="evenodd" fill="url(#XMLID_59_)" d="M16.6,1.2c0.2,0.1,0.5,0.2,0.9,0.2
          c1.1,0.2,2.3,1.1,2.7,2.3c0.1,0.4,0.3,1,0.5,1.7c-0.1,0-0.3,0-0.4-0.2c-0.6-0.8-1.6-2.5-2.9-3.4C17,1.7,16.8,1.4,16.6,1.2z"/>
        <path id="XMLID_32_" fill-rule="evenodd" clip-rule="evenodd" fill="#B8BABC" d="M16.6,1.2c0.7,0.3,1.6,0.4,2.2,0.9
          c0.6,0.5,1,1.3,1.2,1.8c0.3,0.9,0.5,0.9,0.7,1.6c-0.1,0-0.3,0-0.4-0.2c-0.6-0.8-1.6-2.5-2.9-3.4C17,1.7,16.8,1.4,16.6,1.2z"/>
        <path id="XMLID_31_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M19.6,4.5c-0.6-0.9-1.4-2-2.3-2.6
          c-0.2-0.2-0.1-0.1-0.3-0.3c0.2,0.1,0.3,0.1,0.5,0.2C19.3,2.4,19.2,2.5,19.6,4.5z"/>
        <path id="XMLID_30_" fill-rule="evenodd" clip-rule="evenodd" d="M4.6,38.3c0,0-0.4,4-0.4,4.6c-0.1,3.9,14.6,3.9,14.5,0
          c0-0.6-0.4-4.6-0.4-4.6C14.6,39.6,8.1,39.6,4.6,38.3z"/>
        <path id="XMLID_29_" fill-rule="evenodd" clip-rule="evenodd" fill="#0D383E" d="M4.9,39.4c-0.1,1.1-0.3,3.1-0.3,3.5
          c-0.1,3.6,13.7,3.6,13.6,0c0-0.4-0.2-2.4-0.3-3.5C14.3,40.5,8.4,40.5,4.9,39.4z"/>
        <path id="XMLID_28_" fill-rule="evenodd" clip-rule="evenodd" d="M3.1,15.9l1.2,7.6c3.9-1,10.2-1,14,0l1.2-7.6
          C16.6,13.3,6.2,13.3,3.1,15.9z"/>
        <path id="XMLID_27_" fill-rule="evenodd" clip-rule="evenodd" fill="#0D383D" d="M3.7,16.6l1,6.4C8.4,22,14.4,22,18,23l1-6.4
          C16.1,14.5,6.7,14.5,3.7,16.6z"/>
        <path id="XMLID_26_" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20" d="M11.4,36.9L11.4,36.9c0.1,0,0.3,0.1,0.3,0.3v0.4
          c0,0.1-0.1,0.2-0.1,0.2V38c0,0.1-0.1,0.1-0.1,0.1h0c-0.1,0-0.1-0.1-0.1-0.1v-0.2c-0.1,0-0.1-0.1-0.1-0.2v-0.4
          C11.1,37,11.2,36.9,11.4,36.9z"/>
      </g>
      </svg>
      `)
  }

  static storeMarker = (color: string) =>
    SVG.dataUrl(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         width="3.96875mm"
         height="3.96875mm"
         viewBox="0 0 3.96875 3.96875"
         version="1.1"
         id="svg8"
         inkscape:version="1.0.1 (1.0.1+r74)"
         sodipodi:docname="store-marker.svg"
         inkscape:export-filename="/home/maxetron/store-marker.svg.png"
         inkscape:export-xdpi="96"
         inkscape:export-ydpi="96">
        <defs
           id="defs2" />
        <sodipodi:namedview
           id="base"
           pagecolor="#ffffff"
           bordercolor="#666666"
           borderopacity="1.0"
           inkscape:pageopacity="0"
           inkscape:pageshadow="2"
           inkscape:zoom="31.678384"
           inkscape:cx="8.0680138"
           inkscape:cy="8.8864915"
           inkscape:document-units="mm"
           inkscape:current-layer="layer1"
           inkscape:document-rotation="0"
           showgrid="false"
           showguides="true"
           showborder="false"
           inkscape:pagecheckerboard="true"
           inkscape:window-width="1920"
           inkscape:window-height="1016"
           inkscape:window-x="0"
           inkscape:window-y="27"
           inkscape:window-maximized="1" />
        <metadata
           id="metadata5">
          <rdf:RDF>
            <cc:Work
               rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type
                 rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              <dc:title></dc:title>
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <g
           inkscape:label="Layer 1"
           inkscape:groupmode="layer"
           id="layer1">
          <circle
             style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.132291"
             id="path18"
             cx="1.984375"
             cy="1.984375"
             r="1.984375" />
          <circle
             style="fill:${color};fill-opacity:1;stroke-width:0.111939"
             id="path20"
             cx="1.984375"
             cy="1.984375"
             r="1.4552083" />
        </g>
      </svg>
      `)

  static vehicleIcon = (bodyColor: string) =>
    SVG.dataUrl(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <!-- Created with Inkscape (http://www.inkscape.org/) -->
      
      <svg
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              width="58.208332mm"
              height="58.208332mm"
              viewBox="0 0 58.208332 58.208332"
              version="1.1"
              id="svg8"
              inkscape:version="0.92.3 (2405546, 2018-03-11)"
              sodipodi:docname="shuttle-icon-2.svg">
          <defs
                  id="defs2" />
          <sodipodi:namedview
                  id="base"
                  pagecolor="#ffffff"
                  bordercolor="#666666"
                  borderopacity="1.0"
                  inkscape:pageopacity="0.0"
                  inkscape:pageshadow="2"
                  inkscape:zoom="1.979899"
                  inkscape:cx="7.6322168"
                  inkscape:cy="184.75282"
                  inkscape:document-units="mm"
                  inkscape:current-layer="layer1"
                  showgrid="true"
                  inkscape:showpageshadow="false"
                  showborder="false"
                  inkscape:window-width="1853"
                  inkscape:window-height="1025"
                  inkscape:window-x="67"
                  inkscape:window-y="27"
                  inkscape:window-maximized="1"
                  fit-margin-top="0"
                  fit-margin-left="0"
                  fit-margin-right="0"
                  fit-margin-bottom="0"
                  inkscape:snap-smooth-nodes="true">
              <inkscape:grid
                      type="xygrid"
                      id="grid815"
                      originx="0"
                      originy="-34.395835" />
          </sodipodi:namedview>
          <metadata
                  id="metadata5">
              <rdf:RDF>
                  <cc:Work
                          rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                              rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                      <dc:title></dc:title>
                  </cc:Work>
              </rdf:RDF>
          </metadata>
          <g
                  inkscape:label="Layer 1"
                  inkscape:groupmode="layer"
                  id="layer1"
                  transform="translate(0,-204.39583)">
              <circle
                      style="fill:#222f3e;fill-opacity:1;stroke:none;stroke-width:0.26499999;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                      id="path5227"
                      cx="29.104166"
                      cy="233.5"
                      r="29.104166" />
              <circle
                      style="fill:${bodyColor};fill-opacity:1;stroke:none;stroke-width:0.35333335;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0"
                      id="path4529"
                      cx="29.104166"
                      cy="233.5"
                      r="21.166664" />
              <path
                      style="fill:#222f3e;fill-opacity:1;stroke:none;stroke-width:0.99999994px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                      d="M 110,62.921875 C 99.408372,62.921875 88.816744,65.281411 80,70 69.403584,75.67103 60,100 60,100 H 50 v 5 l 10,5 10e-7,35.00001 c 0,6.66667 19.999996,6.66667 20,0 C 80.000005,140 80,130 80,130 h 60 l 0,15.00001 c 0,6.66667 20,6.66667 20,0 L 160,110 l 10,-5 v -5 h -10 c 0,0 -9.40358,-24.32897 -20,-30 -8.81674,-4.718589 -19.40837,-7.078125 -30,-7.078125 z"
                      transform="matrix(0.26458333,0,0,0.26458333,0,204.39583)"
                      id="path5443"
                      inkscape:connector-curvature="0"
                      sodipodi:nodetypes="ssccccssccsscccccs" />
              <path
                      style="fill:${bodyColor};fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                      d="m 39.6875,230.85417 c 0,0 -1.244014,-4.54144 -2.645833,-5.29167 -3.110351,-1.66461 -12.764649,-1.66461 -15.875,0 -1.401819,0.75023 -2.645834,5.29167 -2.645834,5.29167 z"
                      id="path5447"
                      inkscape:connector-curvature="0"
                      sodipodi:nodetypes="csscc" />
              <path
                      style="fill:${bodyColor};fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                      d="m 39.6875,233.5 c 0.357736,0.25784 0,2.64583 0,2.64583 h -5.291667 c 0,0 4.160408,-3.46119 5.291667,-2.64583 z"
                      id="path5449"
                      inkscape:connector-curvature="0"
                      sodipodi:nodetypes="sccs" />
              <path
                      style="fill:${bodyColor};fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                      d="m 18.520833,236.14583 c 0,0 -0.357736,-2.38799 0,-2.64583 1.131259,-0.81536 5.291666,2.64583 5.291666,2.64583 z"
                      id="path5451"
                      inkscape:connector-curvature="0"
                      sodipodi:nodetypes="cscc" />
          </g>
      </svg>
      `)

  static storeIcon = (color: string) =>
    SVG.dataUrl(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <!-- Created with Inkscape (http://www.inkscape.org/) -->
      
      <svg
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              width="58.208332mm"
              height="58.208332mm"
              viewBox="0 0 58.208332 58.208332"
              version="1.1"
              id="svg8"
              inkscape:version="0.92.3 (2405546, 2018-03-11)"
              sodipodi:docname="store-icon.svg">
          <defs
                  id="defs2" />
          <sodipodi:namedview
                  id="base"
                  pagecolor="#ffffff"
                  bordercolor="#666666"
                  borderopacity="1.0"
                  inkscape:pageopacity="0.0"
                  inkscape:pageshadow="2"
                  inkscape:zoom="1.4"
                  inkscape:cx="23.6283"
                  inkscape:cy="72.267047"
                  inkscape:document-units="mm"
                  inkscape:current-layer="layer1"
                  showgrid="true"
                  inkscape:showpageshadow="false"
                  showborder="false"
                  inkscape:window-width="1853"
                  inkscape:window-height="1025"
                  inkscape:window-x="67"
                  inkscape:window-y="27"
                  inkscape:window-maximized="1"
                  fit-margin-top="0"
                  fit-margin-left="0"
                  fit-margin-right="0"
                  fit-margin-bottom="0">
              <inkscape:grid
                      type="xygrid"
                      id="grid815"
                      originx="0"
                      originy="-34.395835" />
          </sodipodi:namedview>
          <metadata
                  id="metadata5">
              <rdf:RDF>
                  <cc:Work
                          rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                              rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                      <dc:title />
                  </cc:Work>
              </rdf:RDF>
          </metadata>
          <g
                  inkscape:label="Layer 1"
                  inkscape:groupmode="layer"
                  id="layer1"
                  transform="translate(0,-204.39583)">
              <circle
                      style="fill:#222f3e;fill-opacity:1;stroke:none;stroke-width:0.26499999;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                      id="path5227"
                      cx="29.104166"
                      cy="233.5"
                      r="29.104166" />
              <circle
                      style="fill:${color};fill-opacity:0.94117647;stroke:none;stroke-width:0.35333335;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0"
                      id="path4529"
                      cx="29.104166"
                      cy="233.5"
                      r="21.166664" />
              <path
                      style="fill:#222f3e;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                      d="M 18.520833,241.4375 H 39.6875 v -10.58333 l -10.583334,-7.9375 -10.583333,7.9375 z"
                      id="path4531"
                      inkscape:connector-curvature="0" />
          </g>
      </svg>
      `)
}
