import React, { useMemo, useState } from 'react'
import { Marker, Popup } from 'react-map-gl/maplibre'
import './styles.scss'
import { SVG } from '../../../utility/SVG'

export const WaypointMarker: React.FC<{
  waypoint: {
    id: string
    name: string
    content: string
    color: string
    place: {
      id: string
      address: string
      latitude: number
      longitude: number
    }
  }
}> = ({ waypoint }) => {
  const [show, setShow] = useState(false)

  return useMemo(() => {
    // Create waypoint marker.
    return (
      <>
        {show && (
          <Popup
            longitude={waypoint.place.longitude}
            latitude={waypoint.place.latitude}
            closeOnClick={false}
            anchor="bottom"
            onClose={() => {
              setShow(false)
            }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 0 }}>{waypoint.name}</p>
            <p style={{ color: 'gray', fontSize: 12 }}>{waypoint.place.address}</p>
            <p style={{ marginBottom: 0 }}>{waypoint.content}</p>
          </Popup>
        )}
        <Marker
          key={waypoint.id}
          anchor="center"
          latitude={waypoint.place.latitude}
          longitude={waypoint.place.longitude}
          onClick={(e) => setShow(true)}
        >
          <div
            className="mouse-pointer"
            style={{
              transform: `translate(${32 + waypoint.name.length / 2}%, -20%)`,
            }}
          >
            <div className="marker-label" style={{ fontSize: '146%' }}>
              {waypoint.name}
            </div>
            <img src={SVG.storeMarker(waypoint.color)} alt={waypoint.name} className="large-icon" />
          </div>
        </Marker>
      </>
    )
  }, [
    show,
    waypoint.id,
    waypoint.name,
    waypoint.content,
    waypoint.color,
    waypoint.place.address,
    waypoint.place.latitude,
    waypoint.place.longitude,
  ])
}
