import React, { useMemo } from 'react'
import distance from '@turf/distance'
import './style.css'
import { Marker } from 'react-map-gl/maplibre'
import BlueDot from '../../../images/blue-dot.png'

export interface ITripMarker {
  trip: {
    store: {
      place: {
        latitude: number
        longitude: number
      }
    }
    steps: {
      id: string
      name: string
      finishedAt: Date | null
      place: {
        address: string
        latitude: number
        longitude: number
      } | null
    }[]
    vehicle: {
      vehicleCoordinate: {
        closestRoadLatitude: number
        closestRoadLongitude: number
      } | null
    } | null
  }
  token: string
}

export const TripMarker: React.FC<ITripMarker> = ({ trip, token }) => {
  const tripNonStorePlaces = trip.steps.filter(
    (s) =>
      s.place &&
      // If the trip marker is on the store, don't display it
      distance(
        [s.place.longitude, s.place.latitude],
        [trip.store.place.longitude, trip.store.place.latitude],
        { units: 'kilometers' },
      ) >= 0.05,
  )
  const tripNonStorePlacesHash = JSON.stringify(tripNonStorePlaces)

  return useMemo(() => {
    const parsedTripNonStorePlaces = JSON.parse(tripNonStorePlacesHash)

    if (parsedTripNonStorePlaces.length) {
      return (
        <>
          {parsedTripNonStorePlaces.map((s: any) => (
            <Marker key={s.id} latitude={s.place.latitude} longitude={s.place.longitude}>
              <div>
                <div className="markerLabels">{s.name}</div>
                <img src={BlueDot} alt={s.name} />
              </div>
            </Marker>
          ))}
        </>
      )
    } else return null
  }, [tripNonStorePlacesHash])
}
