import React, { ReactNode, useEffect, useState } from 'react'
import { AutoComplete, Input, message, Spin } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '../../hooks/useDebounce'

interface IPlacesAutocomplete {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  style?: any
  onSelect?: (value: any) => void
  size?: 'large'
  max?: number
  token: string
  kioskShortUrl: string
}

interface IPrediction {
  value: string
  label: ReactNode
  coordinates?: [number, number]
}

export const PlacesAutocomplete: React.FC<IPlacesAutocomplete> = ({
  value = '',
  onChange,
  placeholder,
  style,
  onSelect,
  size,
  max,
  token,
  kioskShortUrl,
}) => {
  const [predictions, setPredictions] = useState<IPrediction[]>([])
  const [t] = useTranslation()
  const debouncedValue = useDebounce(value, 500)
  const [loading, setLoading] = useState(false)
  const [favorites, setFavorites] = useState<IPrediction[]>([])

  useEffect(() => {
    fetch(`${window.SC.apiUrl}/v2/places/favorites/${kioskShortUrl}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          message.error(t(`error.server.${json.error.message}`))
        } else if (json && json.length) {
          const jsonFavoritse = json.map((p: any) => ({
            value: p.address,
            label: p.address,
            coordinates: [p.longitude, p.latitude],
          }))

          setFavorites(jsonFavoritse)
          setPredictions(jsonFavoritse)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }, [kioskShortUrl, t, token])

  useEffect(() => {
    const input = encodeURIComponent(debouncedValue)

    if (input.length > 3) {
      setLoading(true)
      fetch(`${window.SC.apiUrl}/v2/mapbox/autocomplete/${kioskShortUrl}?input=${input}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setLoading(false)

          if (json.error) {
            message.error(t(`error.server.${json.error.message}`))
          } else if (json && json.length) {
            setPredictions([
              ...json.map((p: any) => ({
                value: p.description,
                label: p.description,
                coordinates: p.coordinates,
              })),
              ...favorites,
            ])
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [debouncedValue, t, kioskShortUrl, token, favorites])

  const handleOnSelect = (value: string, selection: { coordinates?: [number, number] }) => {
    if (onSelect && selection.coordinates) {
      onSelect({
        address: value,
        longitude: selection.coordinates[0],
        latitude: selection.coordinates[1],
      })
    }
  }

  return (
    <AutoComplete
      options={predictions}
      value={value}
      onChange={onChange}
      style={style}
      onSelect={handleOnSelect}
    >
      <Input
        prefix={
          <EnvironmentOutlined
            style={{ color: 'rgba(0,0,0,.25)' }}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        placeholder={placeholder}
        size={size}
        max={max}
        suffix={loading ? <Spin style={{ height: '20px' }} /> : null}
      />
    </AutoComplete>
  )
}
